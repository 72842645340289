export const EXAM_LIST_HEADERS = [
  {
    text: 'Test Name',
    value: 'exam_name',
  },
  {
    text: 'Question Groups',
    value: 'tags',
    sortable: false,
  },
  {
    text: 'Questions',
    value: 'question_count',
  },
  {
    text: 'Generated on',
    value: 'date_created',
  },
  {
    text: '',
    value: 'actions',
    width: 50,
    sortable: false,
  },
];

export const CANDIDATE_LIST_HEADERS = [
  {
    text: 'E-Mail',
    value: 'assigned_email',
    sortable: false,
  },
  {
    text: 'Test Name',
    value: 'exam_name',
    sortable: false,
  },
  {
    text: 'Deadline Date',
    value: 'deadline_date',
    sortable: false,
  },
  {
    text: 'Status',
    value: 'is_finished',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    width: 50,
    sortable: false,
  },
];

export const MY_RESULTS_HEADERS = [
  {
    text: 'E-Mail',
    value: 'assigned_email',
    sortable: false,
  },
  {
    text: 'Test Name',
    value: 'exam_name',
    sortable: false,
  },
  {
    text: 'Deadline Date',
    value: 'deadline_date',
    sortable: false,
  },
  {
    text: 'Score',
    value: 'exam_question_groups',
    sortable: false,
  },
  {
    text: 'Total Point',
    value: 'total_point',
    sortable: false,
  },
];

export const QUESTION_LIST_HEADERS = [
  {
    text: 'Tags',
    value: 'tags',
    sortable: false,
  },
  {
    text: 'Question',
    value: 'question',
    sortable: false,
  },
  {
    text: '',
    value: 'actions',
    width: 50,
    sortable: false,
  },
];

export const REDUCED_QUESTION_LIST_HEADERS = [
  {
    text: 'Question',
    value: 'question',
    sortable: false,
  },
  {
    text: 'Add',
    value: 'actions',
    sortable: false,
    width: 50,
  },
];

export const RULE_LIST_HEADERS = [
  {
    text: 'Text',
    value: 'result',
    sortable: false,
  },
  {
    text: 'Min',
    value: 'min_value',
  },
  {
    text: 'Max',
    value: 'max_value',
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: 80,
  },
];
