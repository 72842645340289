<template>
  <div class="d-flex align-center information-checkboxes-wrapper">
    <span class="information-text">{{ text }}</span>
    <v-icon>mdi-arrow-right-thin</v-icon>
    <v-checkbox class="ml-2 pt-0" :color="color" :input-value="true" readonly hide-details dense></v-checkbox>
  </div>
</template>
<script>
export default {
  name: 'InformationCheckboxes',
  props: {
    color: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.information-checkboxes-wrapper {
  .information-text {
    min-width: 250px;
  }
}
</style>
