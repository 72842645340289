<template>
  <v-dialog v-model="dialog" width="800px" @click:outside="close">
    <v-card class="add-question-modal">
      <div class="close-icon">
        <v-icon size="18" @click="close">mdi-close</v-icon>
      </div>
      <div class="header">
        <div class="breadcrumb">
          <div class="name disabled">XperExam</div>
          <v-icon>mdi-chevron-right</v-icon>
          <div class="name disabled">Question Pool</div>
          <v-icon>mdi-chevron-right</v-icon>
          <div class="name">Add New Question</div>
        </div>
      </div>
      <div class="content">
        <div class="form-title">
          <span class="form-name">{{ title }}</span>
          <v-btn-toggle v-model="questionType" dense>
            <v-btn :value="QUESTIONS_TYPE.MULTIPLE_CHOICE">
              <span>Multiple Choice</span>
            </v-btn>
            <v-btn :value="QUESTIONS_TYPE.TRUE_FALSE">
              <span>True / False</span>
            </v-btn>
            <v-btn :value="QUESTIONS_TYPE.OPEN_ENDED">
              <span>Open Ended</span>
            </v-btn>
            <v-btn :value="QUESTIONS_TYPE.MATCHING" disabled>
              <span>Matching</span>
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="question-wrapper">
          <v-textarea class="question-text" outlined v-model="question" placeholder="Enter your question here..." no-resize rows="2" hide-details></v-textarea>
          <div class="choices" v-if="questionType !== QUESTIONS_TYPE.OPEN_ENDED">
            <div v-for="(choice, i) in choices" :key="i" class="choice">
              <v-checkbox dense value :input-value="choice.is_true" @click="selectChoiceAsTrue(i)" hide-details></v-checkbox>
              <v-text-field
                v-model="choice.main_choice"
                placeholder="Enter Choice"
                dense
                hide-details
                outlined
                append-outer-icon="mdi-close"
                @click:append-outer="deleteChoiceFromList(i)"
              ></v-text-field>
            </div>
            <div class="mt-2" v-if="questionType !== QUESTIONS_TYPE.TRUE_FALSE || choices.length < 2">
              <v-btn text @click="addChoiceToList" small>
                <v-icon>mdi-plus</v-icon>
                <span>Add New Choice</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="tags-wrapper mt-4">
        <div class="tag-group-select mb-4">
          <v-select
            :loading="tagGroupLoadings"
            :items="tagGroups"
            item-text="tag_group_name"
            item-value="id"
            v-model="tagGroup"
            label="Tag Group"
            outlined
            dense
            style="width: 100px"
            class="mr-5"
            hide-details
          ></v-select>
          <v-select
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            v-model="difficulty"
            label="Difficulty"
            outlined
            dense
            style="width: 100px"
            class="ml-5"
            hide-details
          ></v-select>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="px-2" v-bind="attrs" v-on="on" size="14">mdi-alert-circle-outline</v-icon>
            </template>
            <span>The order of difficulty is from smallest(1) to largest(10).</span>
          </v-tooltip>
        </div>
        <div class="tags" v-if="tagGroup">
          <span>Tag this question with a measured skill or skills*</span>
          <v-autocomplete
            v-model="selectedTags"
            chips
            deletable-chips
            multiple
            small-chips
            :items="tags"
            item-text="tag_name"
            item-value="id"
            :loading="tagsLoading"
            item-color="primary"
            color="white"
            outlined
            label="Tags"
            placeholder="Tags"
            hide-details
            dense
          ></v-autocomplete>
        </div>
      </div>
      <div class="footer">
        <v-alert dense outlined type="error" v-if="errorText">
          {{ errorText }}
        </v-alert>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addQuestion" :loading="loading">{{ btnText }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../lib/services/api';
import { OPEN_ENDED, QUESTIONS_TYPE } from '../../lib/constants/question';

export default {
  name: 'AddQuestionModal',
  props: {
    editModel: Object,
  },
  data() {
    return {
      QUESTIONS_TYPE,
      dialog: true,
      errorText: '',
      loading: false,
      questionType: QUESTIONS_TYPE.MULTIPLE_CHOICE,
      question: '',
      choices: [],
      tagGroups: [],
      tagGroupLoadings: true,
      tagGroup: null,
      tags: [],
      tagsLoading: false,
      selectedTags: [],
      difficulty: 1,
    };
  },
  computed: {
    isSingleChoice() {
      return this.questionType === QUESTIONS_TYPE.TRUE_FALSE;
    },
    title() {
      return this.editModel ? 'Update Question' : 'Add Question';
    },
    btnText() {
      return this.editModel ? 'Update' : 'Add';
    },
  },
  created() {
    this.fetchTagGroups();
    this.fillFormWithEditModel();
    this.fetchQuestionDetail();
  },
  methods: {
    validate() {
      if (!this.question || this.question.length < 3) {
        return 'Question must be at least 3 characters long';
      }

      if (this.selectedTags.length === 0) {
        return 'You must be select at least one tag';
      }
      if (this.questionType !== OPEN_ENDED) {
        if (this.choices.length < 2) {
          return 'You must be add at least two choice';
        }

        if (this.choices.every((c) => !c.is_true)) {
          return 'You must be select at least one correct answers';
        }

        if (this.choices.some((c) => !c.main_choice.trim())) {
          return 'You must fill all choices';
        }
      }

      return null;
    },
    fillFormWithEditModel() {
      if (this.editModel) {
        this.questionType = this.editModel.question_type;
        this.difficulty = this.editModel.difficulty;
        this.question = this.editModel.question;
        this.tagGroup = this.editModel.tags[0].tag_group;
        this.selectedTags = this.editModel.tags.map((t) => t.id);
      }
    },
    close() {
      this.$emit('close');
      this.dialog = false;
      this.$modal.close();
    },
    selectChoiceAsTrue(index) {
      this.choices = this.choices.map((c, i) => {
        let isTrue = i === index ? !c.is_true : c.is_true;

        return {
          ...c,
          is_true: isTrue,
        };
      });
    },
    async fetchTagGroups() {
      try {
        const response = await api.fetchTagGroups();
        this.tagGroups = response.data.tag_group_list;
        this.tagGroupLoadings = false;
      } catch (err) {
        this.tagGroupLoadings = false;
        this.$emit('error', "Couln't fetch tag groups.");
      }
    },
    async fetchTags() {
      try {
        this.tagsLoading = true;
        const response = await api.fetchTags({ tag_group_id: this.tagGroup });

        if (response?.status === 200 && response?.data?.data) {
          this.tags = response.data.data;
        }
        this.tagsLoading = false;
      } catch (error) {
        this.tagsLoading = false;
      }
    },
    async addQuestion() {
      try {
        this.errorText = this.validate();
        if (this.errorText) {
          return;
        }

        const payload = {
          tags: this.selectedTags,
          question: this.question,
          question_type: this.questionType,
          difficulty: this.difficulty,
        };

        this.loading = true;
        let questionId;

        if (this.editModel) {
          await api.updateQuestion(Object.assign(payload, { id: this.editModel.id }));
          questionId = this.editModel.id;
        } else {
          const res = await api.addQuestion(payload);
          questionId = res.data.question_id;
        }

        await this.addChoices(questionId);
        this.loading = false;
        this.$emit('success', 'Question was successfully added.');
        this.$emit('completed');
        this.close();
      } catch (err) {
        this.loading = false;
        this.$emit('error', "Couln't add question.");
      }
    },
    async fetchQuestionDetail() {
      if (!this.editModel) {
        return;
      }
      const response = await api.fetchQuestionDetailById(this.editModel.id);
      this.choices = response.data.choice;
    },
    async addChoices(id) {
      const data = {
        question_id: id,
        ChoiceList: this.choices,
      };
      try {
        await api.addChoice(data);
      } catch (error) {
        this.$emit('error', error);
      }
    },
    addChoiceToList() {
      this.choices.push({
        main_choice: '',
        is_true: false,
        main_media_url: '',
        score: 0,
      });
    },
    deleteChoiceFromList(index) {
      this.$delete(this.choices, index);
    },
  },
  watch: {
    tagGroup: 'fetchTags',
    questionType() {
      if (this.questionType === QUESTIONS_TYPE.TRUE_FALSE) {
        this.choices = this.choices.splice(0, 2).map((c) => ({
          ...c,
          is_true: false,
        }));
      }
    },
  },
};
</script>

<style lang="scss">
.add-question-modal {
  display: block;
  padding: 3rem;
  position: relative;

  fieldset {
    border-color: #d3dce6 !important;
  }

  .close-icon {
    position: absolute;
    top: 25px;
    right: 25px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3dce6;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem 0 0.5rem;

    .breadcrumb {
      display: flex;
      font-weight: bold;
      font-size: 14px;

      .disabled {
        color: silver;
      }
    }
  }

  .content {
    .form-title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 12px;
      margin-top: 3rem;

      .form-name {
        font-weight: bold;
        font-size: 17px;
      }

      .v-item-group {
        background-color: white !important;

        .v-item--active {
          background-color: #27ae60 !important;
          color: white;
        }
      }
    }

    .question-wrapper {
      .question-text {
        border-radius: 0 !important;
      }

      .choices {
        border: 1px solid #d3dce6 !important;
        padding: 1rem;

        .choice {
          display: flex;
          align-items: center;

          fieldset {
            border: none;
          }
        }
      }
    }
  }

  .tags-wrapper {
    .v-chip {
      background: #ffb906;
      color: white;
      border-radius: 3px;

      .v-icon {
        color: white;
      }
    }

    .tags {
      span {
        font-size: 12px;
        color: black;
      }
      fieldset {
        border: none;
      }
    }

    .tag-group-select {
      display: flex;
      justify-content: space-between;
    }
  }

  .footer {
    display: flex;
    text-align: end;
    border-top: 1px solid #d3dce6;
    padding-top: 1rem;
    margin-top: 1rem;

    .v-btn {
      min-width: 150px;
    }
  }
}
</style>
