<template>
  <div class="result-progress-overlay">
    <v-row align="center">
      <v-col cols="3">
        {{ name }}
        <v-tooltip v-if="unscoredOpenEnded" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" size="14">mdi-alert-circle-outline</v-icon>
          </template>
          <span>Has Unscored Open Ended</span>
        </v-tooltip>
      </v-col>
      <v-col :cols="barCols">
        <v-progress-linear :value="score" :color="color" height="16">
          <strong>{{ Math.ceil(score) }}%</strong>
        </v-progress-linear>
      </v-col>
      <v-col v-if="detailId" cols="2">
        <div class="detail-text" @click="goDetail">{{ detailText }}</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ResultProgress',
  props: {
    name: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'amber',
    },
    unscoredOpenEnded: Boolean,
    detailId: String,
    detailText: {
      type: String,
      default: 'See Detail',
    },
  },
  computed: {
    barCols() {
      let col = 9;
      if (this.detailId) {
        col -= 2;
      }
      return col;
    },
  },
  data: () => ({
    knowledge: 90,
  }),
  methods: {
    goDetail() {
      this.$router.push(`/xperexam/result/${this.detailId}`).then(() => {
        this.$emit('close');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.result-progress-overlay {
  .detail-text {
    font-size: 11px;
    line-height: 24px;
    font-weight: 300;
    color: #2f80ed;
    text-align: center;
    cursor: pointer;
  }
}
</style>
