var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScaffolding',{attrs:{"user":_vm.user,"avatar-text":_vm.avatarText,"title":(_vm.headerText + " (" + (this.totalCount) + ")")},scopedSlots:_vm._u([{key:"left",fn:function(){return undefined},proxy:true},{key:"top",fn:function(){return undefined},proxy:true}])},[_c('div',{staticClass:"candidates-page"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.exams,"headers":_vm.candidateHeader,"hide-default-footer":"","dense":"","options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.exam_name",fn:function(ref){
var item = ref.item;
return [((item.id && item.is_finished) || item.assigned_exam_id)?_c('router-link',{attrs:{"to":("/xperexam/result/" + (item.id || item.assigned_exam_id))}},[_vm._v(" "+_vm._s(item.exam_name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.exam_name)+" ")])]}},{key:"item.deadline_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"gray-column"},[_vm._v(" "+_vm._s(_vm.formatDate(item.deadline_date, 'DD MMM YYYY HH:mm'))+" ")])]}},{key:"item.is_finished",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"gray-column exam",class:{ completed: item.is_finished }},[_vm._v(" "+_vm._s(item.is_finished ? 'Completed' : 'Uncompleted')+" ")])]}},{key:"item.exam_question_groups",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"gray-column"},[_vm._v(" Show Results "),_c('v-icon',{staticClass:"mr-2 open-in-new",attrs:{"size":"14"},on:{"click":function($event){return _vm.showResultModal(item)}}},[_vm._v("mdi-open-in-new")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('div',{staticClass:"action-menu"},[_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.repeatTest(item)}}},[_vm._v("Repeat The Test")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("Delete")])],1)])]}}],null,true),model:{value:(_vm.selectedExams),callback:function ($$v) {_vm.selectedExams=$$v},expression:"selectedExams"}}),_c('AppPagination',{attrs:{"total":_vm.totalCount,"limit":this.limit,"page":_vm.page},on:{"change":function (v) { return (_vm.page = v); }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }