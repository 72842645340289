<template>
  <div class="result-question-group-wrapper">
    <div class="d-flex flex-column px-4 py-2">
      <span class="title">{{ title }}</span>
      <span class="tag">{{ tag }}</span>
    </div>
    <v-divider class="mb-4 mt-2"></v-divider>
    <QuestionDetail v-for="(q, i) in mappedQuestions" :key="q.id" :question="q" :groupId="groupId" :index="i" preview result />
  </div>
</template>
<script>
import QuestionDetail from '../Common/QuestionDetail';

export default {
  name: 'ResultQuestionGroup',
  components: {
    QuestionDetail,
  },
  props: {
    title: String,
    tag: String,
    questions: Array,
    groupId: String,
  },
  computed: {
    mappedQuestions() {
      return this.questions.map((question) => {
        let questionRes = {
          ...question,
        };
        if (question?.choices) {
          const choices = question.choices.map((choice) => {
            const isSelectedChoice = question.answers.some((answer) => answer.choice.id === choice.id);
            return {
              ...choice,
              isSelectedChoice,
            };
          });
          questionRes.choice = choices;
        } else {
          questionRes.answer = question.answers[0].text;
        }
        return questionRes;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.result-question-group-wrapper {
  .title {
    font-size: 18px;
    font-weight: 700;
  }
  .tag {
    font-size: 14px;
  }
}
</style>
