<template>
  <div class="app-scaffolding">
    <div class="left">
      <div class="user-info-bg"></div>
      <div class="user-info-wrapper">
        <v-avatar size="90" color="grey lighten-3">{{ avatarText }}</v-avatar>
        <div class="user-info">
          <div class="user-info__name">{{ user.firstName }} {{ user.lastName }}</div>
          <div class="user-info__title">Bites - Recruting Specialist</div>
        </div>
      </div>
      <slot name="left"></slot>
    </div>
    <div class="content">
      <div class="top">
        <div class="page-title">{{ title }}</div>
        <slot name="top"></slot>
      </div>
      <div class="bottom">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppScaffolding',
  props: {
    title: {
      type: String,
      default: null,
    },
    user: Object,
    avatarText: String,
  },
};
</script>

<style lang="scss">
.app-scaffolding {
  display: flex;
  height: 100%;

  .left {
    border-right: 1px solid #979797;
    padding: 1rem;
    min-width: 336px;
    max-height: 100vh;
    overflow-y: auto;
    padding-top: 70px;

    .user-info-bg {
      background: white;
      height: 78px;
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid #979797;
      width: 336px;
    }

    .user-info-wrapper {
      position: absolute;
      display: flex;
      top: -20px;
      z-index: 1;

      .user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        font-weight: 600;
      }

      .user-info__name {
        font-size: 18px;
        color: #333269;
      }

      .user-info__title {
        font-size: 14px;
        color: #979797;
      }

      .v-avatar {
        border: 6px solid #ffb906 !important;
      }
    }
  }

  .content {
    width: 100%;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #979797;
      padding: 1rem;
      height: 66px;

      .v-btn {
        border-color: #d3dce6;
        color: #3c4858;
        font-weight: normal;
        font-size: 14px;
      }

      .page-title {
        font-weight: normal;
        font-size: 23px;
        color: #000000;
      }
    }

    .bottom {
    }
  }
}
</style>
