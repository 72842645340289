const initialState = () => {
  return {
    user: null,
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_USER(state, payload) {
      state.user = {
        ...payload,
        fullName: `${payload?.given_name} ${payload?.family_name}`,
      };
    },
  },
  actions: {},
  getters: {
    avatarText(state) {
      const firstNameLetter = state.user?.given_name[0] || '';
      const lastNameLetter = state.user?.family_name[0] || '';

      return `${firstNameLetter}${lastNameLetter}`;
    },
  },
};
