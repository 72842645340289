<template>
  <div>
    <component :is="layoutComponent" />
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import Layout from '@/views/layouts/Layout';
import PublicLayout from '@/views/layouts/PublicLayout';

export default {
  name: 'App',
  components: {
    Layout,
    PublicLayout,
  },
  computed: {
    layoutComponent() {
      const layout = this.$route.meta?.layout;
      switch (layout) {
        case 'public':
          return PublicLayout;
        default:
          return Layout;
      }
    },
  },
};
</script>

<style lang="scss">
.v-main {
  padding: 0 !important;
}
</style>
