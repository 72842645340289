<template>
  <div class="question-detail mb-4">
    <div class="question-wrapper">
      <v-btn icon small v-if="preview && !result" @click="deleteQuestionFromGroup" :disabled="!deletable">
        <v-icon color="red" size="18">mdi-delete-outline</v-icon>
      </v-btn>
      <div class="question ml-2">
        <b class="mr-2">{{ index + 1 }}.</b>
        {{ questionDetail.question }}
        <v-divider v-if="!loading && result"></v-divider>
      </div>
    </div>
    <div v-if="loading">...loading</div>

    <div
      class="choices ml-5 mt-2"
      v-if="questionDetail.question_type === QUESTIONS_TYPE.MULTIPLE_CHOICE || questionDetail.question_type === QUESTIONS_TYPE.TRUE_FALSE"
    >
      <div class="choice" v-for="choice in questionDetail.choice" :key="choice.id">
        <v-checkbox :color="checkboxColor(choice)" :input-value="choiceValue(choice)" :readonly="preview" hide-details dense></v-checkbox>
        {{ choice.main_choice }}
      </div>
    </div>

    <div class="choices ml-5 mt-2" v-else-if="questionDetail.question_type === QUESTIONS_TYPE.OPEN_ENDED && result && !loading">
      {{ questionDetail.answer }}
      <div class="gray-column my-2">Answer</div>
      <div class="d-flex">
        <v-select :items="points" label="Open Ended" solo v-model="selectedPoint" hide-details dense class="mr-2" style="max-width: 100px"></v-select>
        <v-btn @click="setAnswer">Set Point</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../lib/services/api';
import { QUESTIONS_TYPE } from '../../lib/constants/question';

export default {
  name: 'QuestionDetail',
  props: {
    question: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    result: {
      type: Boolean,
      default: false,
    },
    groupId: String,
  },
  data() {
    return {
      QUESTIONS_TYPE,
      questionDetail: this.question,
      loading: false,
      points: [],
      selectedPoint: null,
    };
  },
  created() {
    this.fetchQuestionDetail();
  },
  methods: {
    deleteQuestionFromGroup() {
      this.$emit('deleteQuestionFromGroup', this.question.id);
    },
    async fetchQuestionDetail() {
      if (this.question.choice || this.question.answer) {
        if (this.question.question_type === QUESTIONS_TYPE.OPEN_ENDED) {
          this.points = [...Array(this.question.max_score + 1).keys()];
          this.selectedPoint = this.question.score;
        }
        return;
      }
      try {
        this.loading = true;
        const response = await api.fetchQuestionDetailById(this.question.id);
        this.questionDetail = response.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$emit('error', err);
      }
    },
    choiceValue(choice) {
      return choice?.is_true || choice?.isSelectedChoice;
    },
    checkboxColor(choice) {
      if (choice.is_true) {
        if (choice?.isSelectedChoice) {
          return 'green';
        }
        if (!this.result) {
          return 'green';
        }
        return 'gray';
      }
      return 'red';
    },
    setAnswer() {
      this.$showConfirmModal(null, {
        confirm: async () => {
          try {
            this.loading = true;
            const payload = {
              id: this.question.assigned_question_id,
              score: this.selectedPoint,
              question_group_id: this.groupId,
            };
            await api.evaluteQuestionAnswer(payload);
            this.$snotify.success('Question score updated successfully');
            this.loading = false;
          } catch (err) {
            this.loading = false;
            this.$snotify.success('Question score update failed');
            this.loading = false;
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.question-detail {
  .open-in-new {
    cursor: pointer;
  }
  padding: 0.5rem;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 3px;

  .question-wrapper {
    display: flex;

    .question {
      display: block;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: auto;
      padding-right: 1rem;
      max-height: 10.5em;
      line-height: 1.8em;
    }
  }

  .choices {
    .choice {
      display: flex;
      align-items: center;
    }
  }
}
</style>
