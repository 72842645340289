export const MULTIPLE_CHOICE = '1';
export const TRUE_FALSE = '2';
export const OPEN_ENDED = '3';
export const MATCHING = '4';
export const CODE = '5';

export const QUESTIONS_TYPE = {
  MULTIPLE_CHOICE,
  TRUE_FALSE,
  OPEN_ENDED,
  MATCHING,
  CODE,
};

export const VIDEO_TYPES = ['mp4', '3gp', 'ogg', 'flv', 'avi'];
export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'jfif', 'tif', 'tiff', 'webp'];
