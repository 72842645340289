var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScaffolding',{attrs:{"user":_vm.user,"avatar-text":_vm.avatarText,"title":("All Tests (" + (this.totalCount) + ")")},scopedSlots:_vm._u([{key:"left",fn:function(){return undefined},proxy:true},{key:"top",fn:function(){return [_c('div',[(_vm.selectedExams.length > 0)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"selected-item-count"},[_vm._v(_vm._s(_vm.selectedExams.length))]),_vm._v(" Selected "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,1098428257)},[_c('div',{staticClass:"action-menu"},[_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.assign(_vm.item)}}},[_vm._v("Send to a Candidate")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item"},[_vm._v("Attach to Job Posting")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item"},[_vm._v("See All Results")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item"},[_vm._v("Edit")])],1)]):_vm._e(),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.addExamClicked()}}},[_vm._v("Add New")])],1)]},proxy:true}])},[_c('div',{staticClass:"my-exam-list"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.exams,"headers":_vm.headers,"hide-default-footer":"","show-select":"","dense":"","options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"gray-column"},[_vm._v(" "+_vm._s(_vm.formatDate(item.date_created, 'DD MMM YYYY'))+" ")])]}},{key:"item.question_count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"gray-column"},[_vm._v(" "+_vm._s(item.question_count)+" ")])]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('Tags',{attrs:{"tags":item.exam_question_groups}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('div',{staticClass:"action-menu"},[_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.assign(item)}}},[_vm._v("Send to a Candidate")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item"},[_vm._v("Attach to Job Posting")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item"},[_vm._v("See All Results")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.addExamClicked(item)}}},[_vm._v("Edit")])],1)])]}}],null,true),model:{value:(_vm.selectedExams),callback:function ($$v) {_vm.selectedExams=$$v},expression:"selectedExams"}}),_c('AppPagination',{attrs:{"total":_vm.totalCount,"limit":this.limit,"page":_vm.page},on:{"change":function (v) { return (_vm.page = v); }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }