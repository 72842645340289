<template>
  <v-dialog v-model="dialog" width="400px" @click:outside="close">
    <v-card class="add-tag-group-modal">
      <v-card-title>
        <div>Add Tag Group</div>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="save" ref="form">
          <v-text-field label="Type tag group." v-model="tagGroup" outlined dense :rules="[rules.required, rules.max(99)]"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../lib/services/api';
import { rules } from '../../lib/utils/form';

export default {
  name: 'AddTagGroupModal',
  props: {
    editModel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rules,
      dialog: true,
      tagGroup: null,
      loading: false,
      id: null,
    };
  },
  created() {
    if (this.editModel) {
      this.tagGroup = this.editModel.name;
      this.id = this.editModel.value;
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.$modal.close();
      this.$emit('close');
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        this.loading = true;

        if (this.id) {
          await api.editTagGroup(this.id, { tag_group_name: this.tagGroup });
        } else {
          await api.addTagGroup({ tag_group_name: this.tagGroup });
        }

        this.$snotify.success('Tag group created successfully');
        this.loading = false;
        this.$emit('completed');
        this.close();
      } catch (error) {
        this.$snotify.error('Tag group couldnt be created');
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-tag-group-modal {
  .error-text {
    color: red;
  }
}
</style>
