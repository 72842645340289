var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScaffolding',{attrs:{"user":_vm.user,"avatar-text":_vm.avatarText,"title":("All Questions (" + (this.totalCount) + ")")},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('FilterBox',{attrs:{"title":"Tag Group","deletable":"","items":_vm.tagGroupItems},on:{"addClicked":function($event){return _vm.onAddTagGroupClicked()},"removeClicked":_vm.removeSelectedTagGroups,"updateClicked":_vm.onAddTagGroupClicked},model:{value:(_vm.selectedTagGroupIds),callback:function ($$v) {_vm.selectedTagGroupIds=$$v},expression:"selectedTagGroupIds"}}),_c('FilterBox',{attrs:{"title":"Tag","deletable":"","items":_vm.tagItems},on:{"addClicked":function($event){return _vm.onAddTagClicked()},"removeClicked":_vm.removeSelectedTags,"updateClicked":_vm.onAddTagClicked},model:{value:(_vm.selectedTagIds),callback:function ($$v) {_vm.selectedTagIds=$$v},expression:"selectedTagIds"}})]},proxy:true},{key:"top",fn:function(){return [_c('div',[(_vm.selectedQuestions.length > 0)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"selected-item-count"},[_vm._v(_vm._s(_vm.selectedQuestions.length))]),_vm._v(" Selected "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2310703518)},[_c('div',{staticClass:"action-menu"},[_c('v-divider'),_c('div',{staticClass:"action-menu-item",on:{"click":_vm.deleteSelectedQuestions}},[_vm._v("Delete")])],1)]):_vm._e(),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.showAddQuestionDialog()}}},[_vm._v("Add New Question")])],1)]},proxy:true}])},[_c('div',{staticClass:"question-list"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.questions,"headers":_vm.headers,"hide-default-footer":"","show-select":"","dense":"","options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('Tags',{attrs:{"tags":item.tags}})]}},{key:"item.question",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"question"},[_vm._v(_vm._s(item.question))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('div',{staticClass:"action-menu"},[_c('div',{staticClass:"action-menu-item",on:{"click":function($event){return _vm.onEditQuestionClicked(item)}}},[_vm._v("Edit")]),_c('v-divider'),_c('div',{staticClass:"action-menu-item",staticStyle:{"color":"red !important"},on:{"click":function($event){return _vm.deleteQuestion(item)}}},[_vm._v("Delete")])],1)])]}}],null,true),model:{value:(_vm.selectedQuestions),callback:function ($$v) {_vm.selectedQuestions=$$v},expression:"selectedQuestions"}}),_c('AppPagination',{attrs:{"total":_vm.totalCount,"limit":_vm.limit,"page":_vm.page},on:{"change":function (v) { return (_vm.page = v); }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }