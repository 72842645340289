import Vue from 'vue';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import { ExamServiceLib } from 'exam-service-web-lib';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Modal from './plugins/modal';
import { consoleLog, consoleError } from './utils/logger';

import 'vue-snotify/styles/material.css';
import './assets/scss/main.scss';

import { keycloakConfig } from './config';

let token;
if (localStorage.getItem('token') === 'undefined' || localStorage.getItem('token') === 'null') {
  token = undefined;
} else {
  token = localStorage.getItem('token') || undefined;
}
const refreshToken = localStorage.getItem('refresh-token') === 'undefined' ? undefined : localStorage.getItem('refresh-token') || undefined;

Vue.config.productionTip = false;
Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightBottom,
  },
});

Vue.use(VueKeycloakJs, {
  init: {
    token,
    refreshToken,
    onLoad: 'check-sso',
  },
  config: keycloakConfig,
  onReady: async (keycloak) => {
    if (keycloak.authenticated) {
      if (keycloak.isTokenExpired()) {
        try {
          const refreshed = await keycloak.updateToken(30);
          if (!refreshed) {
            keycloak.login();
            return;
          }
        } catch (e) {
          keycloak.login();
          return;
        }
      }
    }

    if (keycloak.authenticated && !keycloak.isTokenExpired() && keycloak.token) {
      ExamServiceLib.setToken(keycloak.token);
      localStorage.setItem('token', keycloak.token);
      localStorage.setItem('refresh-token', keycloak.refreshToken);
      const user = await keycloak.loadUserInfo();
      store.commit('Auth/SET_USER', user, { root: true });
    }

    keycloak.onTokenExpired = () => {
      consoleLog('token expired');
      keycloak
        .updateToken(5)
        .then(() => {
          consoleLog('token updated');
          localStorage.setItem('token', keycloak.token);
          localStorage.setItem('refresh-token', keycloak.refreshToken);
          ExamServiceLib.setToken(keycloak.token);
        })
        .catch(consoleError);
    };

    const app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
      watch: {
        '$keycloak.token': {
          immediate: true,
          handler: function (newToken) {
            ExamServiceLib.setToken(keycloak.token);
            localStorage.setItem('token', newToken);
          },
        },
      },
    }).$mount('#app');
    Vue.use(Modal, { appInstance: app });
  },
});
