<template>
  <div class="my-exam" v-if="notCompleted">
    <div class="my-exam-header">
      <span class="title">{{ title }}</span>
      <v-spacer></v-spacer>
      <span class="time">
        <v-icon>mdi-clock</v-icon>
        {{ formattedRemainigTime }}
      </span>
    </div>
    <Question v-if="currentQuestion" :data="currentQuestion" @answerChanged="handleAnswerChange" @answerTextChanged="handleTextAnswerChange" />
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">Warning</v-card-title>
        <v-card-text>Are you sure about the finish exam?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="submitExam">Finish</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="bottom-pagination">
      <v-spacer></v-spacer>
      <div class="pagination-buttons">
        <v-btn fab dark x-small color="primary" @click="goToFirst" :loading="loading" :disabled="prevIsDisabled">
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-container class="max-width">
          <v-pagination v-model="page" class="my-4" :length="this.questions.length" :total-visible="20" circle></v-pagination>
        </v-container>
        <v-btn fab dark x-small color="primary" @click="goToLast" :loading="loading" :disabled="isLastQuestion">
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn class="finish-wrapper" color="#72CB8F" @click="dialog = true">Finish Exam</v-btn>
    </div>
  </div>
  <div v-else class="completed my-10">
    <div class="completed-content" style="text-align: center">
      <v-icon size="100" color="green">mdi-check-decagram-outline</v-icon>
      <span style="font-size: 24px">Test is completed successfully!</span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import api from '../../lib/services/api';
import { consoleError, consoleLog } from '../../lib/utils/logger';
import { OPEN_ENDED, MULTIPLE_CHOICE, TRUE_FALSE, MATCHING, CODE } from '../../lib/constants/question';
import Question from '../Question/Question';

export default {
  name: 'Exam',
  components: {
    Question,
  },
  data() {
    return {
      loading: false,
      isStarted: false,
      notCompleted: true,
      dialog: false,
      remainigTime: 0,
      questionTimer: 0,
      page: +this.$route.query.q || 1,
      questions: [],
      currentAnswers: [],
      currentTextAnswer: '',
      title: null,
      currentQuestion: null,
    };
  },
  created() {
    this.fetchExam();
  },
  computed: {
    isLastQuestion() {
      return this.page === this.questions.length;
    },
    prevIsDisabled() {
      return this.page === 1 || this.loading;
    },
    currentQuestionId() {
      return this.questions[this.page - 1]?.question_id;
    },
    formattedRemainigTime() {
      return new Date(this.remainigTime * 1000).toISOString().substr(11, 8);
    },
    questionType() {
      return this.currentQuestion?.question_details?.question_type;
    },
    isAnswerSubmitable() {
      if (this.questionType === OPEN_ENDED && this.currentTextAnswer) {
        return true;
      }

      if (Array.isArray(this.currentAnswers)) {
        return this.currentAnswers.length > 0;
      }

      return this.currentAnswers;
    },
  },
  methods: {
    goToLast() {
      this.page = this.questions.length;
    },
    goToFirst() {
      this.page = 1;
    },
    async fetchExam() {
      try {
        this.loading = true;
        const response = await api.fetchAssignedExamById(this.$route.params.examId);
        if (!response.data.start_date) {
          this.$router.push(`/prestart/${this.$route.params.examId}`);
          return;
        }

        const startDate = dayjs(response.data.start_date);
        const { duration } = response.data;
        const diff = dayjs(new Date()).diff(startDate, 'second') - 60; //1min for ready
        this.remainigTime = duration - diff;
        this.startDurationCount();

        if (response.data.finish_date) {
          this.notCompleted = false;
          return;
        }

        this.questions = response.data.exam_question_group.reduce((acc, curr) => {
          curr.question_list.forEach((question) => {
            acc.push({
              ...question,
              exam_question_group_id: curr.exam_question_group_id,
            });
          });
          return acc;
        }, []);

        this.fetchCurrentQuestion();
      } catch (err) {
        consoleError(err);
        this.$snotify.error('Fetch exam error');
        this.loading = false;
        this.$emit('error', { ...err, code: 404 });
      }
    },
    startDurationCount() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        this.remainigTime = this.remainigTime - 1;
      }, 1000);
    },
    async fetchCurrentQuestion() {
      try {
        this.loading = true;
        const payload = {
          assigned_exam_id: this.$route.params.examId,
          question_id: this.currentQuestionId,
        };
        const response = await api.fetchQuestionById(payload);
        this.currentQuestion = {
          ...this.questions[this.page - 1],
          ...response.data,
        };

        this.loading = false;
        this.startQuestionTimer();
      } catch (err) {
        consoleError(err);
        this.$snotify.error('Fetch question error');
        this.loading = false;
      }
    },
    startQuestionTimer() {
      if (this.questionTimerInerval) {
        clearInterval(this.questionTimerInerval);
      }

      this.questionTimer = 0;
      this.questionTimerInerval = setInterval(() => {
        this.questionTimer = this.questionTimer + 1;
      }, 1000);
    },
    async submitExam() {
      try {
        await this.submitAnswer();
        await api.submitExam(this.$route.params.examId);
        this.dialog = false;
        this.notCompleted = false;
        this.$snotify.success('Exam is completed!');
      } catch (err) {
        consoleError(err);
        this.$snotify.error('Submit exam error');
      }
    },
    handleAnswerChange(newAnswers) {
      this.currentAnswers = newAnswers;
    },
    handleTextAnswerChange(newAnswers) {
      this.currentTextAnswer = newAnswers;
      this.autoSubmitAnswer();
    },
    autoSubmitAnswer: debounce(function () {
      this.submitAnswer();
    }, 500),
    async submitAnswer() {
      try {
        if (!this.isAnswerSubmitable) return;

        const question = this.currentQuestion;
        const payload = {
          assigned_question: {
            question_id: question.question_id,
            id: question.assigned_question_id,
            assigned_exam_id: this.$route.params.examId,
            answer_time: this.questionTimer,
            question_group_id: question.exam_question_group_id,
          },
        };

        switch (this.questionType) {
          case OPEN_ENDED:
            payload.answer = [this.currentTextAnswer];
            break;
          case MULTIPLE_CHOICE:
            payload.answer = Array.isArray(this.currentAnswers) ? this.currentAnswers : [this.currentAnswers];
            break;
          case TRUE_FALSE:
            payload.answer = [this.currentAnswers];
            break;
          case MATCHING:
          case CODE:
            consoleLog('Question Type: ', this.questionType);
            break;
          default:
            payload.answer = Array.isArray(this.currentAnswers) ? this.currentAnswers : [this.currentAnswers];
        }

        await api.submitAnswer(payload);
        this.currentAnswers = [];
        this.currentTextAnswer = '';
      } catch (err) {
        consoleError(err);
        this.$snotify.error('Submit answer error');
      }
    },
    onPageChange() {
      window.history.pushState(window.location.href, '', `?q=${this.page}`);
      this.submitAnswer();
      this.fetchCurrentQuestion();
    },
  },
  watch: {
    page: 'onPageChange',
  },
};
</script>

<style lang="scss" scoped>
.my-exam {
  padding-bottom: 50px;
  .my-exam-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .my-exam-actions {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  .completed {
    width: 100%;
    height: 100%;

    .completed-content {
      font-size: 50px;
      font-weight: bold;
      text-align: center;
    }
  }

  .bottom-pagination {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    height: 80px;
    padding: 1rem;
    align-items: center;
    width: 100%;
    left: 0;
    background-color: white;
    border-top: 1px solid silver;
    .pagination-buttons {
      display: flex;
      align-items: center;
    }
    .finish-wrapper {
      display: flex;
      position: absolute;
      right: 10px;
      bottom: 1.5rem;
    }
  }
  .submit-answer-button {
    text-transform: none;
    position: fixed;
    right: 1rem;
    bottom: 7rem;
    z-index: 10;
  }
  .submit-exam-button {
    text-transform: none;
    position: fixed;
    right: 5rem;
    bottom: 5.5rem;
    z-index: 10;
  }
}
</style>
