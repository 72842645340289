import axios from 'axios';
import { toParam } from '../utils/misc';
import { serviceConfig } from '../../config';

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: serviceConfig.baseURL,
    });
  }

  setToken(token) {
    if (token) {
      this.api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }

  addQuestion(payload) {
    return this.api.post('/question', payload);
  }

  createExam(payload) {
    return this.api.post('/exam', payload);
  }

  fetchExamById(id) {
    return this.api.get(`/exam/${id}`);
  }

  updateExam(id, payload) {
    return this.api.put(`/exam/${id}`, payload);
  }

  fetchExams(params) {
    return this.api.get(`/exam/get-all?${toParam(params)}`);
  }

  fetchUnassignedExams() {
    return this.api.get('/unassigned-exams');
  }

  fetchMyExams(params = {}) {
    return this.api.get(`/assigned-exam/?${toParam(params)}`);
  }

  fetchAssignedExamById(id) {
    return this.api.get(`/assigned-exam/${id}`);
  }

  fetchExamUsers(examId) {
    return this.api.get(`/assigned-exam-users/${examId}`);
  }

  removeAssignedExam(id) {
    return this.api.delete(`/assigned-exam/${id}`);
  }

  assignExamToUser(data) {
    return this.api.post('/assigned-exam', data);
  }

  fetchGroupRules(groupId) {
    return this.api.get(`/exam-rule/${groupId}`);
  }

  addGroupRule(data) {
    return this.api.post('/exam-rule', data);
  }

  updateGroupRule(data) {
    return this.api.put(`/exam-rule/${data.id}`, data);
  }

  deleteGroupRule(id) {
    return this.api.delete(`/exam-rule/${id}`);
  }

  fetchQuestionById(data) {
    return this.api.post('/assigned-question-detail', data);
  }

  fetchQuestions(params) {
    return this.api.get(`/question/get-by-params?${toParam(params)}`);
  }

  fetchGroupQuestions(id) {
    return this.api.get(`/exam-group-questions/${id}`);
  }

  addQuestionToGroup(data) {
    return this.api.post('/exam-question', data);
  }

  deleteQuestionFromGroup(id) {
    return this.api.delete(`/exam-question/${id}`);
  }

  startExam(examId, data) {
    return this.api.put(`/assigned-exam/start/${examId}`, data);
  }

  submitExam(examId) {
    return this.api.put(`/assigned-exam/finish/${examId}`);
  }

  submitAnswer(data) {
    return this.api.post('/submit-question-answer', data);
  }

  fetchAnswers(params) {
    return this.api.get(`/submit-question-answer?${toParam(params)}`);
  }

  fetchResults(data) {
    return this.api.post('/filter-resulted-exams', data);
  }

  fetchFinishedExamQuestions(data) {
    return this.api.post('/finished-exam-questions', data);
  }

  fetchFinishedExanAnswers(data) {
    return this.api.post('/finished-exam-answers', data);
  }

  addQuestionGroup(data) {
    return this.api.post('/question-group', data);
  }

  updateQuestionGroup(data) {
    return this.api.put(`/question-group/${data.id}`, data);
  }

  deleteQuestionGroup(id) {
    return this.api.delete(`/question-group/${id}`);
  }

  fetchQuestionGroups(data) {
    return this.api.get(`/question-group/?${toParam(data)}`);
  }

  fetchQuestionGroupsByExam(id) {
    return this.api.get(`/question-groups-by-exam/${id}`);
  }

  addQuestionGroupToExam(data) {
    return this.api.post('/add-question-group-to-exam', data);
  }

  removeQuestionGroupFromExam(data) {
    return this.api.post('/remove-question-group-to-exam', data);
  }

  evaluteQuestionAnswer(data) {
    return this.api.post('/evaluate-question-answer', data);
  }

  fetchTagGroups() {
    return this.api.get('/tag-group/');
  }

  deleteTagGroup(id) {
    return this.api.delete(`/tag-group/${id}`);
  }

  editTagGroup(id, payload) {
    return this.api.put(`/tag-group/${id}`, payload);
  }

  fetchQuestionsWithParams(qsParams) {
    return this.api.get(`/question/get-by-params?${qsParams}`);
  }

  fetchQuestionDetailById(id) {
    return this.api.get(`/question/${id}`);
  }

  addTagGroup(data) {
    return this.api.post('/tag-group', data);
  }

  addTag(data) {
    return this.api.post('/tag', data);
  }

  deleteTag(id) {
    return this.api.delete(`/tag/${id}`);
  }

  editTag(id, payload) {
    return this.api.put(`/tag/${id}`, payload);
  }

  fetchTags(data = {}) {
    return this.api.get(`/tag/?${toParam(data)}`);
  }

  createQuestion(data) {
    return this.api.post('/question', data);
  }

  updateQuestion(payload) {
    return this.api.put(`/question/${payload.id}`, payload);
  }

  addChoice(data) {
    return this.api.post('/choice', data);
  }

  deleteQuestion(id) {
    return this.api.delete(`/question/${id}`);
  }
}

export default new Api();
