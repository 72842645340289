<template>
  <div class="question">
    <div v-if="hasMedia">
      <v-img class="mb-5" v-if="isMediaImg" :src="data.question_details.media_url" max-height="400" contain></v-img>
      <video class="q-video mb-5" v-else-if="isMediaVid" controls :src="data.question_details.media_url"></video>
      <div v-else>Wrong data type for media</div>
    </div>
    <div class="question-text mb-5">{{ data.question_details.question }}</div>
    <div v-if="isMultiChoice">
      <v-list>
        <v-list-item-group v-model="selectedAnswers" multiple>
          <div v-for="(choice, i) in data.question_details.choices" :key="`item-${i}`">
            <v-divider></v-divider>
            <v-list-item :value="choice.id">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="choice.main_choice"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </div>
    <div v-else-if="isSingleChoice">
      <v-radio-group v-model="selectedAnswers">
        <v-list>
          <v-list-item v-for="choice in data.question_details.choices" :key="choice.id">
            <v-radio :label="choice.main_choice" :value="choice.id"></v-radio>
          </v-list-item>
        </v-list>
      </v-radio-group>
    </div>
    <div v-else-if="isOpenEnded">
      <v-textarea name="input-7-1" v-model="answerText" outlined hint="Enter your answer"></v-textarea>
    </div>
    <div v-else-if="isCode">
      <CodeEditor />
    </div>
  </div>
</template>

<script>
import { MULTIPLE_CHOICE, OPEN_ENDED, TRUE_FALSE, VIDEO_TYPES, IMAGE_TYPES, CODE } from '../../lib/constants/question';
import CodeEditor from '../CodeEditor/CodeEditor';

export default {
  name: 'Question',
  extension: String,
  components: {
    CodeEditor,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answerText: '',
      selectedAnswers: [],
    };
  },
  computed: {
    questionType() {
      return this.data.question_details.question_type;
    },
    multiepleSelectable() {
      return this.data.question_details.multiple_selectable;
    },
    isMultiChoice() {
      return this.questionType === MULTIPLE_CHOICE && this.multiepleSelectable;
    },
    isSingleChoice() {
      return (this.questionType === MULTIPLE_CHOICE && !this.multiepleSelectable) || this.questionType === TRUE_FALSE;
    },
    isOpenEnded() {
      return this.questionType === OPEN_ENDED;
    },
    isCode() {
      return this.questionType === CODE;
    },
    hasMedia() {
      return this.data.question_details.media_url;
    },
    isMediaImg() {
      return IMAGE_TYPES.includes(this.data.question_details.media_url.split('.').pop());
    },
    isMediaVid() {
      return VIDEO_TYPES.includes(this.data.question_details.media_url.split('.').pop());
    },
  },
  watch: {
    selectedAnswers() {
      this.$emit('answerChanged', this.selectedAnswers);
    },
    answerText() {
      this.$emit('answerTextChanged', this.answerText);
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        const { answers } = this.data;
        const type = this.questionType;
        if (answers.length > 0) {
          if (type === OPEN_ENDED) {
            this.answerText = answers[0].text;
          } else if (type === MULTIPLE_CHOICE) {
            this.selectedAnswers = this.isSingleChoice ? answers[0].choice.id : answers.map((a) => a.choice.id);
          } else if (type === TRUE_FALSE) {
            this.selectedAnswers = answers[0].choice.id;
          }
        } else {
          this.answerText = '';
          this.selectedAnswers = [];
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  padding: 1rem;
  height: 100%;

  .question-text {
    padding: 1rem;
    font-size: 24px;
  }

  .q-video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}
</style>
