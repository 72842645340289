<template>
  <AppScaffolding :user="user" :avatar-text="avatarText" :title="`All Questions (${this.totalCount})`">
    <template v-slot:left>
      <FilterBox
        title="Tag Group"
        deletable
        :items="tagGroupItems"
        v-model="selectedTagGroupIds"
        @addClicked="onAddTagGroupClicked()"
        @removeClicked="removeSelectedTagGroups"
        @updateClicked="onAddTagGroupClicked"
      />
      <FilterBox
        title="Tag"
        deletable
        :items="tagItems"
        v-model="selectedTagIds"
        @addClicked="onAddTagClicked()"
        @removeClicked="removeSelectedTags"
        @updateClicked="onAddTagClicked"
      />
    </template>
    <template v-slot:top>
      <div>
        <v-menu bottom left offset-y v-if="selectedQuestions.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="mr-2" v-bind="attrs" v-on="on">
              <span class="selected-item-count">{{ selectedQuestions.length }}</span>
              Selected
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="action-menu">
            <v-divider></v-divider>
            <div class="action-menu-item" @click="deleteSelectedQuestions">Delete</div>
          </div>
        </v-menu>
        <v-btn outlined @click="showAddQuestionDialog()">Add New Question</v-btn>
      </div>
    </template>
    <div class="question-list">
      <v-data-table
        :loading="loading"
        :items="questions"
        :headers="headers"
        hide-default-footer
        show-select
        v-model="selectedQuestions"
        dense
        :options.sync="options"
        :server-items-length="totalCount"
      >
        <template v-slot:[`item.tags`]="{ item }">
          <Tags :tags="item.tags" />
        </template>
        <template v-slot:[`item.question`]="{ item }">
          <span class="question">{{ item.question }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <div class="action-menu">
              <div class="action-menu-item" @click="onEditQuestionClicked(item)">Edit</div>
              <v-divider></v-divider>
              <div class="action-menu-item" style="color: red !important" @click="deleteQuestion(item)">Delete</div>
            </div>
          </v-menu>
        </template>
      </v-data-table>
      <AppPagination :total="totalCount" :limit="limit" :page="page" @change="(v) => (page = v)" />
    </div>
  </AppScaffolding>
</template>

<script>
import debounce from 'lodash.debounce';
import Tags from '../Common/Tags';
import AppPagination from '../Common/AppPagination';
import AppScaffolding from '../Common/AppScaffolding';
import FilterBox from '../Common/FilterBox';
import AddTagModal from '../Modals/AddTagModal.vue';
import AddTagGroupModal from '../Modals/AddTagGroupModal.vue';
import AddQuestionModal from '../Modals/AddQuestionModal.vue';
import api from '../../lib/services/api';
import { formatDate } from '../../lib/utils/date';
import { QUESTION_LIST_HEADERS } from '../../lib/constants/headers';

export default {
  name: 'QuestionList',
  props: {
    user: Object,
    avatarText: String,
    searchText: String,
  },
  components: {
    Tags,
    AppPagination,
    AppScaffolding,
    FilterBox,
  },
  data() {
    return {
      loading: false,
      headers: QUESTION_LIST_HEADERS,
      selectedQuestions: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      questions: [],
      options: {},
      tagGroups: [],
      tagGroupLoadings: true,
      selectedTagGroupIds: [],
      tags: [],
      selectedTagIds: [],
      tagsLoading: true,
    };
  },
  created() {
    this.fetchQuestions();
    this.fetchTagGroups();
  },
  computed: {
    params() {
      const { sortBy, sortDesc } = this.options;
      const q = {
        limit: this.limit,
        offset: this.page > 1 ? (this.page - 1) * this.limit : 0,
      };

      if (sortBy && sortBy[0]) {
        q.sort_by = sortDesc[0] ? `-${sortBy[0]}` : sortBy[0];
      }

      if (this.searchText) {
        q.text = this.searchText;
      }

      if (this.selectedTagGroupIds.length > 0) {
        q.tag_group_id = this.selectedTagGroupIds;
      }

      if (this.selectedTagIds.length > 0) {
        q.tag_id = this.selectedTagIds;
      }

      return q;
    },
    tagGroupItems() {
      return this.tagGroups.map((tg) => ({
        ...tg,
        value: tg.id,
        name: tg.tag_group_name,
      }));
    },
    tagItems() {
      return this.tags.map((tg) => ({
        ...tg,
        value: tg.id,
        name: tg.tag_name,
      }));
    },
  },
  methods: {
    formatDate,
    async fetchQuestions() {
      try {
        this.loading = true;
        const response = await api.fetchQuestions(this.params);
        this.questions = response.data.questions;
        this.totalCount = response.data.total_element;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$emit('error', "Couln't fetch questions.");
      }
    },
    search: debounce(function () {
      this.fetchQuestions();
    }, 500),
    deleteQuestion(question) {
      this.$showConfirmModal(null, {
        confirm: async () => {
          try {
            this.loading = true;
            await api.deleteQuestion(question.id);
            this.fetchQuestions();
            this.$emit('success', 'Question was successfully deleted');
          } catch (err) {
            this.loading = false;
            this.$emit('error', "`Couln't delete question.");
          }
        },
      });
    },
    deleteSelectedQuestions() {
      this.$showConfirmModal(null, {
        confirm: async () => {
          try {
            this.loading = true;
            const promises = this.selectedQuestions.map((q) => api.deleteQuestion(q.id));
            await Promise.all(promises);
            this.$emit('success', 'Questions was successfully deleted');
            this.page = 1;
            this.fetchQuestions();
            this.selectedQuestions = [];
          } catch (err) {
            this.loading = false;
            this.$emit('error', "`Couln't delete question.");
          }
        },
      });
    },
    async fetchTagGroups() {
      try {
        const response = await api.fetchTagGroups();
        this.tagGroups = response.data.tag_group_list;
        this.tagGroupLoadings = false;
        this.fetchTags();
      } catch (err) {
        this.tagGroupLoadings = false;
        this.$emit('error', "Couln't fetch tag groups.");
      }
    },
    async fetchTags() {
      try {
        this.tagsLoading = false;
        const response = await api.fetchTags();
        this.tags = response.data.data;
        this.tagsLoading = false;
      } catch (err) {
        this.tagsLoading = false;
        this.$emit('error', "Couln't fetch tag groups.");
      }
    },
    showAddQuestionDialog(question) {
      this.$showModal(
        AddQuestionModal,
        {
          editModel: question,
        },
        {
          completed: this.fetchQuestions,
        },
      );
    },
    onEditQuestionClicked(question) {
      if (question.question_groups.length > 0) {
        this.$emit('error', 'This question can not update');
        return;
      }

      this.showAddQuestionDialog(question);
    },
    onAddTagClicked(editModel) {
      this.$showModal(
        AddTagModal,
        { editModel },
        {
          completed: this.fetchTags,
        },
      );
    },
    onAddTagGroupClicked(editModel) {
      this.$showModal(
        AddTagGroupModal,
        { editModel },
        {
          completed: this.fetchTagGroups,
        },
      );
    },
    removeSelectedTagGroups() {
      this.$showConfirmModal(null, {
        confirm: async () => {
          try {
            const promises = this.selectedTagGroupIds.map((id) => api.deleteTagGroup(id));
            await Promise.all(promises);
            this.$emit('success', 'Tag Group(s) was successfully deleted');
            this.fetchTagGroups();
            this.selectedTagGroupIds = [];
          } catch (err) {
            this.$emit('error', "`Couln't delete tag group(s).");
          }
        },
      });
    },
    removeSelectedTags() {
      this.$showConfirmModal(null, {
        confirm: async () => {
          try {
            const promises = this.selectedTagIds.map((id) => api.deleteTag(id));
            await Promise.all(promises);
            this.fetchTags();
            this.selectedTagIds = [];
            this.$emit('success', 'Tag(s) was successfully deleted');
          } catch (err) {
            this.$emit('error', "`Couln't delete tag(s).");
          }
        },
      });
    },
  },
  watch: {
    options: {
      deep: true,
      handler: 'fetchQuestions',
    },
    selectedTagGroupIds: {
      deep: true,
      handler() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchQuestions();
        }
      },
    },
    selectedTagIds: {
      deep: true,
      handler() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchQuestions();
        }
      },
    },
    page: 'fetchQuestions',
    searchText: 'search',
  },
};
</script>

<style lang="scss" scoped>
.question-list {
  .question {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: auto;
    padding-right: 1rem;
    max-height: 7.4em;
    line-height: 1.8em;
  }
}
.selected-item-count {
  color: var(--primary-color);
  margin-right: 10px;
  font-weight: bold;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}
</style>
