export const toParam = (query) => {
  const arrays = [];

  for (const q in query) {
    if (Array.isArray(query[q])) {
      arrays.push({ name: q, values: query[q] });
      delete query[q];
    }
  }

  let str = new URLSearchParams(query).toString();
  arrays.forEach((a) => {
    a.values.forEach((value) => {
      if (str.length > 0) {
        str += `&${a.name}=${value}`;
      } else {
        str += `${a.name}=${value}`;
      }
    });
  });

  return str;
};
