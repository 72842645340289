<template>
  <div>
    <v-chip class="exam-tag mx-1" v-for="tag in visibleTags" :key="tag.id" small>{{ tag.title || tag.tag_name }}</v-chip>
    <v-menu bottom v-if="isExceeded">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <div class="exceeded-list">
        <v-chip class="exam-tag mr-1 my-1" small v-for="tag in exceededTags" :key="tag.id">{{ tag.title || tag.tag_name }}</v-chip>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    maxVisibleTagCount: {
      type: Number,
      default: 2,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    visibleTags() {
      return [...this.tags].splice(0, this.maxVisibleTagCount);
    },
    isExceeded() {
      return this.tags.length > this.maxVisibleTagCount;
    },
    exceededTags() {
      return [...this.tags].splice(this.maxVisibleTagCount, this.tags.length - 1);
    },
  },
};
</script>

<style lang="scss">
.exam-tag {
  background-color: var(--light-yellow) !important;
  color: white !important;
  border-radius: 3px !important;
  font-size: 11px !important;
  padding: 5px !important;
}

.exceeded-list {
  height: 200px;
  width: 200px;
  background: white;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 0.5rem;
}
</style>
