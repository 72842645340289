import { render, staticRenderFns } from "./AddQuestionModal.vue?vue&type=template&id=5da2067d&"
import script from "./AddQuestionModal.vue?vue&type=script&lang=js&"
export * from "./AddQuestionModal.vue?vue&type=script&lang=js&"
import style0 from "./AddQuestionModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../exam-service-web/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../exam-service-web/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAutocomplete,VBtn,VBtnToggle,VCard,VCheckbox,VDialog,VIcon,VSelect,VSpacer,VTextField,VTextarea,VTooltip})
