import api from './services/api';
import storage from './services/storage';
import events from './utils/EventEmitter';
import * as constants from './constants';

export default {
  api,
  storage,
  constants,
  events,
  setToken(token) {
    api.setToken(token);
  },
};
