<template>
  <AppScaffolding :user="user" :avatar-text="avatarText" :title="`${headerText} (${this.totalCount})`">
    <template v-slot:left></template>
    <template v-slot:top>
      <!-- <div>
        <v-menu bottom left offset-y v-if="selectedExams.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="mr-2" v-bind="attrs" v-on="on">
              <span class="selected-item-count">{{ selectedExams.length }}</span>
              Selected
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="action-menu">
            <div class="action-menu-item" @click="assign(item)">Send to a Candidate</div>
            <v-divider></v-divider>
            <div class="action-menu-item">Attach to Job Posting</div>
            <v-divider></v-divider>
            <div class="action-menu-item">See All Results</div>
            <v-divider></v-divider>
            <div class="action-menu-item">Edit</div>
          </div>
        </v-menu>
        <v-btn outlined @click="addExamClicked()">Add New</v-btn>
      </div> -->
    </template>
    <div class="candidates-page">
      <v-data-table
        :loading="loading"
        :items="exams"
        :headers="candidateHeader"
        hide-default-footer
        v-model="selectedExams"
        dense
        :options.sync="options"
        :server-items-length="totalCount"
      >
        <template v-slot:[`item.exam_name`]="{ item }">
          <router-link v-if="(item.id && item.is_finished) || item.assigned_exam_id" :to="`/xperexam/result/${item.id || item.assigned_exam_id}`">
            {{ item.exam_name }}
          </router-link>
          <div v-else>
            {{ item.exam_name }}
          </div>
        </template>
        <template v-slot:[`item.deadline_date`]="{ item }">
          <span class="gray-column">
            {{ formatDate(item.deadline_date, 'DD MMM YYYY HH:mm') }}
          </span>
        </template>
        <template v-slot:[`item.is_finished`]="{ item }">
          <span class="gray-column exam" :class="{ completed: item.is_finished }">
            {{ item.is_finished ? 'Completed' : 'Uncompleted' }}
          </span>
        </template>
        <template v-slot:[`item.exam_question_groups`]="{ item }">
          <span v-if="item" class="gray-column">
            Show Results
            <v-icon class="mr-2 open-in-new" size="14" @click="showResultModal(item)">mdi-open-in-new</v-icon>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <div class="action-menu">
              <!-- <div class="action-menu-item" @click="assign(item)">Send result to</div>
              <v-divider></v-divider>
              <div class="action-menu-item">Export</div>
              <v-divider></v-divider> -->
              <div class="action-menu-item" @click="repeatTest(item)">Repeat The Test</div>
              <v-divider></v-divider>
              <div class="action-menu-item" @click="onDelete(item)">Delete</div>
            </div>
          </v-menu>
        </template>
      </v-data-table>
      <AppPagination :total="totalCount" :limit="this.limit" :page="page" @change="(v) => (page = v)" />
    </div>
  </AppScaffolding>
</template>
<script>
import debounce from 'lodash.debounce';
import api from '../../lib/services/api';
import { CANDIDATE_LIST_HEADERS, MY_RESULTS_HEADERS } from '../../lib/constants/headers';
import { formatDate } from '../../lib/utils/date';
import AppPagination from '../Common/AppPagination';
import AppScaffolding from '../Common/AppScaffolding';
import MyResultsModal from '../Modals/MyResultsModal';
import AssignExamModal from '../Modals/AssignExamModal';

export default {
  name: 'Candidates',
  props: {
    user: Object,
    avatarText: String,
    searchText: String,
    fetchMethod: String,
    header: String,
    headerText: String,
  },
  components: {
    AppPagination,
    AppScaffolding,
  },
  data() {
    return {
      CANDIDATE_LIST_HEADERS,
      MY_RESULTS_HEADERS,
      loading: false,
      page: 1,
      limit: 10,
      totalCount: 0,
      selectedExams: [],
      exams: [],
      options: {},
    };
  },
  computed: {
    examParams() {
      // const { sortBy, sortDesc } = this.options;
      const q = {
        limit: this.limit,
        offset: this.page > 1 ? (this.page - 1) * this.limit : 0,
      };

      // if (sortBy && sortBy[0]) {
      //   q.sort_by = sortDesc[0] ? `-${sortBy[0]}` : sortBy[0];
      // }

      if (this.searchText) {
        q.search_text = this.searchText;
      }

      return q;
    },
    candidateHeader() {
      return this[this.header];
    },
  },
  methods: {
    formatDate,
    async fetchExams() {
      try {
        this.loading = true;
        const response = await api[this.fetchMethod](this.examParams);
        this.exams = response.data?.data;
        this.totalCount = response.data?.total_element || response.data?.count || 0;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$snotify.error("Couldn't fetch candidates");
      }
    },
    search: debounce(function () {
      this.fetchExams();
    }, 500),
    async onDelete(item) {
      this.$showConfirmModal(null, {
        confirm: async () => {
          this.loading = true;
          try {
            await api.removeAssignedExam(item.id);
            this.fetchExams();
          } catch (error) {
            this.loading = false;
            this.$snotify.error("Couldn't remove candidate");
          }
        },
      });
    },
    showResultModal(data) {
      this.$showModal(MyResultsModal, { data });
    },
    repeatTest(item) {
      const exam = {
        id: item.exam_id,
        duration: Number(item.duration),
        assigned_email: item.assigned_email,
        deadline_date: item.deadline_date,
      };
      this.$showModal(AssignExamModal, { exam, fetchAfterCreate: true }, { fetchExams: this.fetchExams });
    },
  },
  created() {
    this.fetchExams();
  },
  watch: {
    // options: {
    //   deep: true,
    //   handler: 'fetchExams',
    // },
    page: 'fetchExams',
    searchText: 'search',
  },
};
</script>
<style lang="scss" scoped>
.candidates-page {
  .open-in-new {
    cursor: pointer;
  }
  .exam {
    color: #ffc107;
    &.completed {
      color: #4caf50;
    }
  }
}
</style>
