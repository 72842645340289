var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600px"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"assign-exam-modal"},[_c('v-card-title',{staticClass:"text-h5 grey lighten-2 mb-5"},[_vm._v("Assign Exam To User")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date","outlined":"","dense":"","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.minDate,"show-current":false},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"type":"number","label":"Duration in Minutes","hide-details":"","outlined":"","dense":""},on:{"keypress":_vm.onDurationKeypress},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Time","outlined":"","dense":"","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[(_vm.timeMenu)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1)],1)],1),_c('div',[_c('v-autocomplete',{attrs:{"items":_vm.emails,"search-input":_vm.emailSearchValue,"outlined":"","dense":"","deletable-chips":"","multiple":"","append-icon":"","small-chips":"","hide-selected":"","hide-no-data":"","persistent-hint":"","label":"Emails"},on:{"update:searchInput":function($event){_vm.emailSearchValue=$event},"update:search-input":function($event){_vm.emailSearchValue=$event},"change":_vm.changeMails,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.onEnterEmail.apply(null, arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{staticClass:"my-1",attrs:{"close":"","small":""},on:{"click:close":function($event){return _vm.unselectEmail(index)}}},[_c('div',{staticClass:"chip-content"},[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.emails),callback:function ($$v) {_vm.emails=$$v},expression:"emails"}})],1),(_vm.emailError)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v("Emails field is required")]):_vm._e(),(_vm.isSmallerTwoDays)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v("Deadline must be at least 2 days after today")]):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.assign}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }