<template>
  <div class="filter-box">
    <div class="filter-title">
      <span>{{ title }}</span>
      <div class="d-flex align-center">
        <v-btn icon small @click="$emit('removeClicked')" class="mr-1" color="red" v-show="showDeleteButton">
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
        <v-btn icon small @click="$emit('addClicked')">
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="filter-list">
      <div class="filter-list-item" v-for="item in items" :key="item.value">
        <div>
          <v-checkbox @change="handleChange(item.value)" class="mr-1" dense hide-details></v-checkbox>
          {{ item.name }}
        </div>
        <v-btn icon small class="update-button" @click="$emit('updateClicked', item)">
          <v-icon size="14" color="green">mdi-pencil</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterBox',
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selecteds() {
      return this.value || [];
    },
    showDeleteButton() {
      return this.selecteds.length && this.deletable;
    },
  },
  methods: {
    handleChange(value) {
      let isDeleted;
      const currentValue = this.selecteds.filter((v) => {
        if (v !== value) {
          return true;
        }

        isDeleted = true;
        return false;
      });

      if (!isDeleted) {
        currentValue.push(value);
      }

      this.$emit('input', currentValue);
    },
  },
};
</script>

<style lang="scss">
.filter-box {
  margin-top: 1rem;
  max-height: 40%;
  overflow-y: auto;

  .filter-title {
    font-weight: 600;
    font-size: 14px;
    color: black;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 0rem 0.1rem;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  .filter-list {
    margin-top: 0.5rem;
    overflow-x: hidden;

    .filter-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;

      div {
        display: flex;
        align-items: center;
      }

      &:hover {
        .update-button {
          display: block;
        }
      }

      .update-button {
        display: none;
      }
    }
  }
}
</style>
