import Vue from 'vue';
import VueRouter from 'vue-router';
import { checkRequiredRoles } from '@/utils/role';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/xperexam/tests',
  },
  {
    path: '/xperexam',
    name: 'XperExam',
    component: () => import('@/views/XperExam.vue'),
    children: [
      {
        path: 'tests',
        component: () => import('@/views/Tests.vue'),
        name: 'Tests',
      },
      {
        path: 'candidates',
        component: () => import('@/views/CandidatesPage.vue'),
        name: 'Candidates',
      },
      {
        path: 'results',
        component: () => import('@/views/ResultsPage.vue'),
        name: 'Results',
      },
      {
        path: 'result/:id',
        component: () => import('@/views/ResultDetailPage.vue'),
        name: 'ResultDetail',
      },
      {
        path: 'question-pool',
        component: () => import('@/views/QuestionPool.vue'),
        name: 'QuestionPool',
      },
    ],
  },
  {
    path: '/question-group/:id?',
    name: 'QuestionGroup',
    component: () => import('@/views/QuestionGroupPage.vue'),
  },
  {
    path: '/prestart/:examId',
    name: 'PrestartPage',
    component: () => import('@/views/PrestartPage.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/exam/:examId',
    name: 'ExamPage',
    component: () => import('@/views/public/ExamPage.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/unauthorized',
    name: '401',
    component: () => import('@/views/public/Unauthorized.vue'),
    meta: {
      layout: 'public',
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/public/NotFound.vue'),
    meta: {
      layout: 'public',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === '401') {
    if (!store.state?.Auth?.user?.role) {
      const loginUrl = router.app.$keycloak.createLoginUrl();
      window.location.href = loginUrl;
    }
    if (store.state?.Auth?.user?.role.includes('LmsAdministrator')) {
      next('/');
    }
    next();
    return;
  }
  if (to.meta.layout !== 'public') {
    if (router.app.$keycloak.authenticated) {
      if (checkRequiredRoles(store.state?.Auth?.user?.role)) {
        next();
      } else {
        next('unauthorized');
      }
    } else {
      const loginUrl = router.app.$keycloak.createLoginUrl();
      window.location.href = loginUrl;
    }
  } else {
    next();
  }
});

export default router;
