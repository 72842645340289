// eslint-disable-next-line consistent-return
function logger(fn = 'log') {
  if (process.env.NODE_ENV === 'development' || fn === 'error') {
    // eslint-disable-next-line
    return Function.prototype.bind.call(console[fn], console, 'Logger: ');
  }
  return () => {};
}

export const consoleLog = logger();
export const consoleDebug = logger('debug');
export const consoleWarning = logger('warn');
export const consoleError = logger('error');
