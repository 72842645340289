<template>
  <AppScaffolding :user="user" :avatar-text="avatarText" title="Result Detail">
    <template v-slot:left>
      <div class="mt-2">
        <div
          class="result-detail-select-group text-center"
          :class="{ active: selectedGroup === key }"
          v-for="(group, key) in questionGroups"
          :key="key"
          @click="changeActiveGroup(key)"
        >
          <span class="my-3">
            {{ group.question_group_title }}
          </span>
        </div>
      </div>
    </template>
    <template v-slot:top></template>

    <div v-if="!loading" class="result-detail">
      <div class="result-head-bar">
        <div class="d-flex justify-space-between px-3 py-3">
          <div>
            <span class="exam-title">Exam Name:</span>
            {{ examName }}
          </div>
          <div>
            <span class="exam-title">Email:</span>
            {{ email }}
          </div>
          <div>
            <span class="exam-title">Start Date:</span>
            {{ startDate }}
          </div>
          <div>
            <span class="exam-title">End Date:</span>
            {{ endDate }}
          </div>
        </div>
      </div>
      <div class="px-4 mt-3">
        <v-row>
          <v-col cols="12" sm="4">
            <span class="exam-title">Selected Colors Meanings</span>
            <information-checkboxes v-for="(information, key) in informations" :key="key" :color="information.color" :text="information.text" />
          </v-col>
          <v-col cols="12" sm="8">
            <span class="exam-title">Score</span>
            <div class="pr-5">
              <result-progress
                v-for="(group, key) in groupScores"
                :key="key"
                :name="group.title"
                :score="calculateScore(group.max_score, group.score)"
                :unscoredOpenEnded="Boolean(group.unscored_open_ended)"
              />
              <v-divider class="my-2"></v-divider>
              <result-progress color="green" name="Overall" :score="generalScore" />
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="result-content px-4">
        <result-question-group
          :title="selectedQuestionGroup.question_group_title"
          :questions="selectedQuestionGroup.questions"
          :tag="selectedQuestionGroup.tag_group_name"
          :groupId="selectedQuestionGroup.question_group_id"
        />
      </div>
    </div>
    <div v-else>
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </div>
  </AppScaffolding>
</template>

<script>
// import QuestionDetail from '../Common/QuestionDetail.vue';
import AppScaffolding from '../Common/AppScaffolding';
import ResultQuestionGroup from './ResultQuestionGroup';
import InformationCheckboxes from '../Common/InformationCheckboxes';
import ResultProgress from '../Common/ResultProgress';
import api from '../../lib/services/api';
import { calculateScore } from '../../lib/utils/calculator';
import { formatDate } from '../../lib/utils/date';
import { consoleError } from '../../lib/utils/logger';

export default {
  name: 'ResultDetail',
  props: {
    user: Object,
    avatarText: String,
  },
  components: {
    AppScaffolding,
    ResultQuestionGroup,
    InformationCheckboxes,
    ResultProgress,
    // QuestionDetail,
  },
  data() {
    return {
      loading: false,
      examName: 'Example Exam',
      startDate: new Date(),
      endDate: new Date(),
      email: 'mmuratersin@gmail.com',
      questionGroups: [],
      groupScores: [],
      informations: [
        { color: 'red', text: 'Selected choice(False answer)' },
        { color: 'green', text: 'Selected choice(True answer)' },
        { color: 'gray', text: 'Unselected choice(True answer)' },
      ],
      selectedGroup: 0,
    };
  },
  computed: {
    selectedQuestionGroup() {
      return this.questionGroups[this.selectedGroup];
    },
    mappedQuestionGroupsByScore() {
      return this.groupScores.map((group) => {
        return this.calculateScore(group.max_score, group.score);
      });
    },
    calculatedGeneralScore() {
      return this.mappedQuestionGroupsByScore.reduce((prev, curr) => {
        return prev + curr;
      });
    },
    generalScore() {
      return Math.ceil(this.calculatedGeneralScore / this.groupScores.length);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    calculateScore,
    formatDate,
    async fetchData() {
      this.loading = true;
      try {
        const payload = {
          assigned_exam_id: this.$route.params.id,
        };
        const res = await api.fetchFinishedExanAnswers(payload);
        const scoreRes = await api.fetchResults(payload);
        if (scoreRes?.data?.data?.length) {
          this.groupScores = scoreRes.data.data[0].exam_question_groups.map((group) => {
            const { score, max_score, unscored_open_ended, title } = group;
            return {
              score,
              max_score,
              unscored_open_ended,
              title,
            };
          });
        }
        // eslint-disable-next-line no-console
        this.startDate = formatDate(res.data?.start_date);
        this.endDate = formatDate(res.data?.finish_date);
        this.examName = res.data?.exam_name;
        this.email = res.data?.assigned_email;
        this.questionGroups = res.data?.question_group;
        this.loading = false;
      } catch (err) {
        consoleError(err);
        this.loading = false;
      }
    },
    changeActiveGroup(groupKey) {
      this.selectedGroup = groupKey;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-detail {
  .result-head-bar {
    background: #49565b85;
  }
  .exam-title {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #333;
  }
}
.result-detail-select-group {
  border: 2px solid #49565b85;
  border-bottom: none;
  cursor: pointer;
  transition: 0.5s;
  &:last-child {
    border-bottom: 2px solid #49565b85;
  }
  &.active {
    background: #49565b59;
    color: white;
    cursor: initial;
  }
}
</style>
