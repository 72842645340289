import Vue from 'vue';
import Vuex from 'vuex';

import Auth from './modules/Auth';

Vue.use(Vuex);

const initialState = () => {
  return {
    searchText: null,
  };
};

export default new Vuex.Store({
  state: initialState(),
  mutations: {
    SET_SEARCH_TEXT(state, payload) {
      state.searchText = payload;
    },
  },
  actions: {},
  modules: {
    Auth,
  },
});
