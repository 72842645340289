export const events = {
  errors: {},
  exam: {},
};

events.exam.SHARED = 'examShared';
events.exam.COMPLETED = 'examCompleted';
events.exam.CREATED = 'examCreated';
events.exam.UPDATED = 'examUpdated';

events.errors.EXAM_FETCH = 'examFetchError';
events.errors.EXAM_CREATE = 'examCreateError';
events.errors.EXAM_UPDATE = 'examUpdateError';
events.errors.EXAM_COMPLETE = 'examCompleteError';
