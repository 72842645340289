<template>
  <v-dialog v-model="dialog" width="800px" @click:outside="close">
    <v-card class="exam-create-modal">
      <v-card-title class="text-h5 grey lighten-2 mb-5">Create Exam</v-card-title>
      <v-card-text>
        <div>
          <v-text-field v-model="examName" outlined dense label="Exam name" name="exam-name"></v-text-field>
          <v-text-field
            v-model="totalPoint"
            @keypress="totalPointKeypress"
            outlined
            dense
            label="Total point"
            hint="Default value 100"
            type="number"
            name="total-point"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-text>
        <div class="exam-create d-flex">
          <v-card style="width: 45%" class="flex-grow-1">
            <v-card-title>
              Question Groups
              <v-spacer></v-spacer>
              <v-btn icon @click="goToQuestionGroup()"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
              <div>
                <v-select v-model="selectedTagGroupId" :items="tagGroups" item-value="id" item-text="tag_group_name" label="Tag Groups"></v-select>
              </div>

              <div v-if="loadingQuestionGroups" class="d-flex justify-center items-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <template v-else>
                <v-radio-group v-model="selectedExamQuestionGroupIdToAdd">
                  <div
                    v-for="questionGroup in questionGroups"
                    :key="questionGroup.id"
                    class="question-group-list-item d-flex"
                    :class="{ disabled: checkQuestionGroupDisable(questionGroup) }"
                  >
                    <v-radio
                      :value="questionGroup.id"
                      :label="questionGroup.title"
                      :input-value="checkQuestionGroupDisable(questionGroup)"
                      :disabled="checkQuestionGroupDisable(questionGroup)"
                      dense
                      hide-details
                      class="mt-0"
                    ></v-radio>
                    <v-spacer></v-spacer>
                    <v-btn x-small icon color="red lighten-2" @click="deleteQuestionGroup(questionGroup.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn x-small icon color="green lighten-2" @click="editQuestionGroup(questionGroup.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </v-radio-group>
              </template>
            </v-card-text>
          </v-card>
          <div class="d-flex flex-column justify-center flex-shrink-0 h-full px-3">
            <v-btn icon :disabled="!selectedExamQuestionGroupIdToAdd" @click="addQuestionGroupToList"><v-icon>mdi-arrow-right</v-icon></v-btn>
          </div>
          <v-card style="width: 45%" class="flex-grow-1">
            <v-card-title>Assigned Question Group List</v-card-title>
            <v-card-text>
              <div v-if="loadingQuestionGroupsByExam" class="d-flex justify-center items-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <template v-else>
                <div v-for="questionGroup in selectedExamQuestionGroups" :key="questionGroup.id" class="question-group-list-item d-flex justify-space-between">
                  {{ questionGroup.name }}
                  <v-btn icon x-small color="red lighten-2" @click="removeQuestionGroupToExam(questionGroup.id)" :loading="loading">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-alert v-show="errorMessage" type="error" dense outlined class="mb-0">{{ errorMessage }}</v-alert>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" :loading="loading" @click="createExam">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../lib/services/api';
import { consoleError } from '../../lib/utils/logger';

export default {
  name: 'CreateExamModal',
  props: {
    editModel: Object,
  },
  data() {
    return {
      dialog: true,
      examCreating: false,
      examName: '',
      totalPoint: null,
      loading: false,
      loadingQuestionGroups: false,
      loadingQuestionGroupsByExam: false,
      selectedExamId: null,
      selectedTagGroupId: null,
      selectedQuestionGroupId: null,
      selectedExamQuestionGroupIdToAdd: null,
      tagGroups: [],
      questionGroups: [],
      selectedExamQuestionGroups: [],
      errorMessage: '',
    };
  },
  created() {
    this.fetchTagGroups();
    this.fillFormWithEditModel();
  },
  methods: {
    validate() {
      if (!this.examName.trim()) {
        return "Exam name can't be blank";
      }

      if (!this.selectedExamQuestionGroups.length) {
        return 'You must be select at least one exam group';
      }

      return null;
    },
    async deleteQuestionGroup(id) {
      this.$showConfirmModal(null, {
        confirm: async () => {
          try {
            await api.deleteQuestionGroup(id);
            if (this.selectedExamQuestionGroupIdToAdd === id) {
              this.selectedExamQuestionGroupIdToAdd = null;
            }
            this.fetchQuestionGroups();
            this.$emit('success', 'Question group successfully deleted.');
          } catch (err) {
            consoleError(err);
            this.$emit('error', "Couln't delete question group.");
          }
        },
      });
    },
    editQuestionGroup(id) {
      this.close();
      this.$router.push(`/question-group/${id}`);
    },
    goToQuestionGroup() {
      this.close();
      this.$router.push('/question-group');
    },
    fillFormWithEditModel() {
      if (this.editModel) {
        this.selectedExamId = this.editModel.id;
        this.examName = this.editModel.exam_name;
        this.totalPoint = this.editModel.total_point;
      }
    },
    close(eventType) {
      this.$emit(eventType === 'completed' ? eventType : 'close');
      this.dialog = false;
      this.$modal.close();
    },
    addQuestionGroupToList() {
      const qg = this.questionGroups
        .filter((q) => this.selectedExamQuestionGroupIdToAdd === q.id)
        .map((d) => ({
          id: d.id,
          name: d.title,
        }));
      this.selectedExamQuestionGroups = [...this.selectedExamQuestionGroups, ...qg];

      if (this.selectedExamId) {
        this.addQuestionGroupToExam(this.selectedExamId);
      }
    },
    async createExam() {
      try {
        if ((this.errorMessage = this.validate())) {
          return;
        }

        this.loading = true;
        const payload = {
          exam_name: this.examName.trim(),
          total_point: +this.totalPoint ?? 100,
        };

        if (this.editModel) {
          const examId = this.editModel.id;
          await api.updateExam(examId, Object.assign(payload, { id: examId }));
        } else {
          const response = await api.createExam(payload);
          await this.addQuestionGroupToExam(response.data.exam_id);
        }

        this.loading = false;
        this.close('completed');
      } catch (err) {
        this.loading = false;
        consoleError(err);
      }
    },
    async addQuestionGroupToExam(examId) {
      const list = this.selectedExamQuestionGroups.map((d) => d.id);

      if (list.length === 0) {
        return;
      }

      try {
        await api.addQuestionGroupToExam({
          exam_id: examId,
          question_group_list: list,
        });
        this.$emit('completed');
      } catch (err) {
        this.loading = false;
        this.$emit('error', err);
      }

      this.selectedExamQuestionGroupIdToAdd = null;
    },
    async removeQuestionGroupToExam(id) {
      if (this.selectedExamId) {
        const data = {
          exam_id: this.selectedExamId,
          question_group_list: [id],
        };

        try {
          this.loading = true;
          await api.removeQuestionGroupFromExam(data);
          this.loading = false;
        } catch (err) {
          this.loading = false;
          consoleError(err);
        }
      }

      this.selectedExamQuestionGroups = this.selectedExamQuestionGroups.filter((g) => g.id !== id);
    },
    updateQuestionGroupToAdd(id) {
      this.selectedExamQuestionGroupIdToAdd = id;
    },
    onExamClicked(id) {
      this.selectedExamQuestionGroups = [];
      this.selectedExamQuestionGroupIdToAdd = null;
      this.selectedExamId = id;
      this.fetchQuestionGroupsByExam();
    },
    checkQuestionGroupDisable() {
      return this.selectedExamQuestionGroups.some((s) => {
        const [group] = this.questionGroups.filter((qg) => qg.id === s.id);
        return group;
      });
    },
    async fetchTagGroups() {
      try {
        this.loading = true;
        const response = await api.fetchTagGroups();
        this.tagGroups = response.data.tag_group_list || [];
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async fetchQuestionGroups() {
      this.questionGroups = [];
      try {
        this.loadingQuestionGroups = true;
        const response = await api.fetchQuestionGroups({ tag_group_id: this.selectedTagGroupId });
        this.questionGroups = response.data.question_group_list || [];

        this.loadingQuestionGroups = false;
      } catch (err) {
        this.loadingQuestionGroups = false;
      }
    },
    async fetchQuestionGroupsByExam(examId) {
      try {
        this.loadingQuestionGroupsByExam = true;
        const response = await api.fetchQuestionGroupsByExam(examId || this.selectedExamId);
        this.selectedExamQuestionGroups = response.data.question_group_list || [];
        this.loadingQuestionGroupsByExam = false;
      } catch (err) {
        this.loadingQuestionGroupsByExam = false;
      }
    },
    totalPointKeypress(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  watch: {
    selectedTagGroupId() {
      if (this.selectedTagGroupId) {
        this.selectedExamQuestionGroupIdToAdd = null;
        this.fetchQuestionGroups();
      }
    },
    selectedExamId: 'onExamClicked',
  },
};
</script>

<style lang="scss">
.exam-create {
  .exam-list-item {
    padding: 0.25rem;
    cursor: pointer;

    &.selected-exam {
      font-weight: bold;
      background-color: powderblue;
    }
  }
  .question-group-list-item {
    padding: 0.25rem;
    cursor: pointer;

    &.selected {
      font-weight: bold;
      background-color: powderblue;
    }
    &.disabled {
      font-weight: bold;
      background-color: #ebebeb;
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>
