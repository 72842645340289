<template>
  <div class="qg-page d-flex flex-column justify-center flex-shrink-0 h-full">
    <h3 v-text="title"></h3>
    <div class="qg d-flex mt-3 justify-center">
      <div class="qg__form d-flex flex-column flex-shrink-0 h-full">
        <h4>Question Group</h4>
        <div class="input-wrapper mt-2">
          <b>Name *</b>
          <v-text-field hide-details outlined dense v-model="questionGroupName" :disabled="!isUpdatable"></v-text-field>
        </div>
        <div class="input-wrapper mt-2" style="width: 40%">
          <b>Tag Group *</b>
          <v-select
            :items="tagGroups"
            outlined
            dense
            hide-details
            item-text="tag_group_name"
            item-value="id"
            v-model="selectedTagGroupId"
            :disabled="!isUpdatable"
          ></v-select>
        </div>
        <div class="questions mt-4">
          <div class="question-list">
            <div class="section-header">
              <span>Questions</span>
              <v-btn @click="onAddQuestionClicked" small color="primary">Add Question</v-btn>
            </div>
            <v-data-table :loading="questionLoading" :items="questionList" :headers="questionHeaders" :server-items-length="totalQuestion" hide-default-footer>
              <template v-slot:[`item.question`]="{ item }" v-if="isUpdatable">
                <div>{{ truncate(item.question, { length: 300 }) }}</div>
              </template>
              <template v-slot:[`item.actions`]="{ item }" v-if="isUpdatable">
                <v-btn @click="toggleAddQuestionModal(item)" :disabled="item.isExists" icon small>
                  <v-icon color="yellow darken-3">mdi-transfer-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <div class="questions-footer">
            <AppPagination :total="totalQuestion" :limit="questionLimit" :page="questionPage" @change="(v) => (questionPage = v)" />
          </div>
        </div>
        <v-divider></v-divider>
        <div class="rules">
          <div class="section-header">
            <span>Rules</span>
            <v-btn @click="onAddRuleClicked()" small color="primary">Add Rule</v-btn>
          </div>
          <div class="rule">
            <v-data-table :loading="ruleLoading" :items="rules" :headers="ruleHeaders" hide-default-footer dense>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon x-small @click="deleteRule(item)" color="red lighten-2" class="mr-2">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn icon x-small @click="onAddRuleClicked(item)" color="green lighten-2">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="tag-group-footer d-flex mt-4">
          <v-alert type="error" v-if="errorMessage" dense>{{ errorMessage }}</v-alert>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createQuestionGroup" :loading="loading" v-text="submitBtnText"></v-btn>
        </div>
      </div>
      <div class="qg__preview d-flex flex-column flex-shrink-0">
        <span class="title">Preview</span>
        <div v-for="(q, i) in groupQuestions" :key="q.id">
          <QuestionDetail :question="q" :index="i" preview @deleteQuestionFromGroup="deleteGroupQuestion(q)" :deletable="isUpdatable" />
          <v-divider></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import truncate from 'lodash.truncate';
import AppPagination from '../Common/AppPagination';
import QuestionDetail from '../Common/QuestionDetail';
import AddRuleToGroupModal from '../Modals/AddRuleToGroupModal';
import AddQuestionModal from '../Modals/AddQuestionModal';
import AddQuestionToGroupModal from '../Modals/AddQuestionToGroupModal';
import { consoleError } from '../../lib/utils/logger';
import { REDUCED_QUESTION_LIST_HEADERS, RULE_LIST_HEADERS } from '../../lib/constants/headers';
import api from '../../lib/services/api';

export default {
  name: 'QuestionGroup',
  components: {
    AppPagination,
    QuestionDetail,
  },
  data() {
    return {
      loading: false,
      tagGroups: [],
      tagGroupLoading: false,
      questionGroupName: null,
      selectedTagGroupId: null,
      questions: [],
      questionLoading: false,
      questionHeaders: REDUCED_QUESTION_LIST_HEADERS,
      questionPage: 1,
      totalQuestion: 0,
      questionLimit: 5,
      groupQuestions: [],
      ruleHeaders: RULE_LIST_HEADERS,
      ruleLoading: false,
      rules: [],
      editModel: null,
      errorMessage: null,
    };
  },
  created() {
    this.fetchTagGroups();
    this.fetchGroupRules();
    this.fetchGroupDetail();
  },
  computed: {
    title() {
      return this.questionGroupId ? 'Update question group' : 'Create a new question group';
    },
    submitBtnText() {
      return this.questionGroupId ? 'Update' : 'Create';
    },
    questionList() {
      return this.questions.map((q) => ({
        ...q,
        isExists: !!this.groupQuestions.find((gq) => gq.id === q.id),
      }));
    },
    questionGroupId() {
      return this.$route.params.id;
    },
    isUpdatable() {
      return !this.editModel || this.editModel.updatable;
    },
  },
  methods: {
    truncate,
    deleteErrorMessage: debounce(function () {
      this.errorMessage = null;
    }, 5000),
    validate() {
      if (!this.questionGroupName) {
        return "Question group name is can't be empty";
      }

      if (!this.selectedTagGroupId) {
        return 'You must be select a tag group';
      }

      if (this.groupQuestions.length === 0) {
        return 'You must add at least one question';
      }

      if (this.rules.length === 0) {
        return 'You must add at least one rule';
      }

      return null;
    },
    async fetchGroupDetail() {
      if (!this.questionGroupId) {
        return;
      }

      try {
        this.fetchGroupQuestions();
        const res = await api.fetchQuestionGroups({ id: this.questionGroupId });
        const [questionGroup] = res.data.question_group_list;
        this.questionGroupName = questionGroup.title;
        this.selectedTagGroupId = questionGroup.tag_group.id;
        this.editModel = { ...questionGroup };
      } catch (err) {
        this.$emit('error', err);
      }
    },
    deleteGroupQuestion(groupQuestion) {
      if (groupQuestion.id) {
        this.groupQuestions = this.groupQuestions.filter((r) => r.id !== groupQuestion.id);
      } else {
        this.groupQuestions = this.groupQuestions.filter((r) => r.tempId !== groupQuestion.tempId);
      }
    },
    async createQuestionGroup() {
      try {
        const errorMessage = this.validate();

        if (errorMessage) {
          this.errorMessage = errorMessage;
          return;
        }

        this.loading = true;
        const questionGroupPayload = {
          tag_group_id: this.selectedTagGroupId,
          title: this.questionGroupName,
          questions: this.groupQuestions.map((q) => ({
            question_id: q.id,
            order: q.order,
            score: q.score,
          })),
        };

        const rulePayload = {
          exam_questions_group_id: '',
          rules: this.rules.map((r) => ({
            min_value: r.min_value,
            max_value: r.max_value,
            result: r.result,
          })),
        };

        if (this.questionGroupId) {
          rulePayload.exam_questions_group_id = this.questionGroupId;
          questionGroupPayload.id = this.questionGroupId;
          await api.updateQuestionGroup(questionGroupPayload);
        } else {
          const res = await api.addQuestionGroup(questionGroupPayload);
          rulePayload.exam_questions_group_id = res.data.exam_question_group;
        }

        if (rulePayload.rules.length > 0) {
          await api.addGroupRule(rulePayload);
        }

        this.loading = false;
        this.$router.push('/xperexam/tests?ref=questionGroup');
      } catch (err) {
        consoleError(err);
        this.$emit('error', err);
        this.loading = false;
      }
    },
    deleteRule(rule) {
      if (rule.id) {
        this.rules = this.rules.filter((r) => r.id !== rule.id);
      } else {
        this.rules = this.rules.filter((r) => r.tempId !== rule.tempId);
      }
    },
    addNewRule(rule) {
      const hasError = this.rules.some((r) => {
        if (r.min_value <= rule.min_value && r.max_value >= rule.min_value) {
          return true;
        }
        if (r.min_value <= rule.max_value && r.max_value >= rule.max_value) {
          return true;
        }
        return false;
      });

      if (hasError) {
        this.errorMessage = 'Rule range is not valid.';
      } else {
        this.rules.push(rule);
      }
    },
    toggleAddQuestionModal(question) {
      this.$showModal(
        AddQuestionToGroupModal,
        {
          questionGroupName: this.questionGroupName,
          question: { ...question },
        },
        {
          completed: (data) => {
            this.groupQuestions.unshift(data);
          },
        },
      );
    },
    onAddQuestionClicked() {
      this.$showModal(
        AddQuestionModal,
        {},
        {
          completed: () => {
            this.fetchQuestions();
          },
        },
      );
    },
    onAddRuleClicked(rule) {
      this.$showModal(
        AddRuleToGroupModal,
        {
          editModel: rule,
        },
        {
          completed: (rule) => {
            this.addNewRule(rule);
          },
        },
      );
    },
    async fetchTagGroups() {
      try {
        const response = await api.fetchTagGroups();
        this.tagGroups = response.data.tag_group_list;
        this.tagGroupLoadings = false;
      } catch (err) {
        consoleError(err);
        this.tagGroupLoadings = false;
        this.$emit('error', "Couln't fetch tag groups.");
      }
    },
    async fetchQuestions() {
      if (!this.selectedTagGroupId) {
        return;
      }
      try {
        const query = {
          limit: this.questionLimit,
          offset: this.questionPage > 1 ? (this.questionPage - 1) * this.questionLimit : 0,
          tag_group_id: this.selectedTagGroupId,
        };
        this.questionLoading = true;
        const response = await api.fetchQuestions(query);
        this.questions = response.data.questions;
        this.totalQuestion = response.data.total_element;
        this.questionLoading = false;
      } catch (err) {
        consoleError(err);
        this.questionLoading = false;
        this.$emit('error', "Couln't fetch questions.");
      }
    },
    async fetchGroupQuestions() {
      try {
        if (!this.questionGroupId) {
          return;
        }
        this.groupQuestionsLoading = true;
        const response = await api.fetchGroupQuestions(this.questionGroupId);
        this.groupQuestions = response.data.question_list.map((q) => ({
          ...q.question_id,
          order: q.order,
          score: q.score,
        }));
        this.groupQuestionsLoading = false;
      } catch (err) {
        consoleError(err);
        this.groupQuestionsLoading = false;
        this.$emit('error', "Couln't fetch questions of group.");
      }
    },
    async fetchGroupRules() {
      try {
        if (!this.questionGroupId) {
          return;
        }
        this.ruleLoading = true;
        const response = await api.fetchGroupRules(this.questionGroupId);
        this.rules = response.data;
        this.ruleLoading = false;
      } catch (err) {
        consoleError(err);
        this.ruleLoading = false;
        this.$emit('error', "Couln't fetch rules.");
      }
    },
  },
  watch: {
    errorMessage() {
      if (this.errorMessage) {
        this.deleteErrorMessage();
      }
    },
    questionPage: 'fetchQuestions',
    selectedTagGroupId() {
      this.fetchQuestions();
      if (!this.editModel || this.editModel.tag_group_id !== this.selectedTagGroupId) {
        this.groupQuestions = [];
      }
    },
  },
};
</script>

<style lang="scss">
.qg-page {
  padding: 1rem;

  .qg {
    border: 1px solid silver;
    height: 100%;

    .qg__form {
      border-right: 1px solid silver;
      width: 60%;
      padding: 1rem;

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(240, 240, 240);
        border-radius: 3px;
        padding: 0.3rem;

        span {
          font-weight: bold;
        }
      }

      .questions {
        min-height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .questions-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .rules {
        .rule {
          max-height: 150px;
          overflow-y: auto;

          .rule-range {
            display: flex;
          }
        }
      }
    }

    .qg__preview {
      width: 40%;
      padding: 1rem;
      overflow-y: auto;
    }
  }
}
</style>
