import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { PRIMARY_COLOR } from '../constants/colors';

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/assets/scss/_variables.scss'],
  treeShake: true,
  theme: {
    themes: {
      light: {
        primary: PRIMARY_COLOR,
      },
    },
  },
});
