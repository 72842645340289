<template>
  <v-dialog v-model="dialog" width="800px" @click:outside="close">
    <v-card class="my-results-modal">
      <v-card-title class="text-h5 grey lighten-2 mb-5 d-flex align-center justify-space-between">
        My Results
        <v-btn icon @click="close">
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <span class="assigned-on">{{ data.assigned_email }} on</span>
            <span class="exam-name ml-2">{{ data.exam_name }}</span>
          </v-col>
          <v-col cols="12">
            <span class="time-spent">Time Spent : 1h 4min</span>
          </v-col>
          <v-col v-for="(group, key) in data.exam_question_groups" :key="key" cols="12">
            <result-progress
              :name="group.title"
              :score="calculateScore(group.max_score, group.score)"
              :unscoredOpenEnded="Boolean(group.unscored_open_ended)"
            />
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <result-progress color="green" :detailId="data.assigned_exam_id" name="Overall" :score="generalScore" @close="close" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import ResultProgress from '../Common/ResultProgress';
import { calculateScore } from '../../lib/utils/calculator';

export default {
  name: 'MyResultsModal',
  components: {
    ResultProgress,
  },
  props: {
    data: Object,
  },
  computed: {
    mappedQuestionGroupsByScore() {
      return this.data.exam_question_groups.map((group) => {
        return this.calculateScore(group.max_score, group.score);
      });
    },
    calculatedGeneralScore() {
      return this.mappedQuestionGroupsByScore.reduce((prev, curr) => {
        return prev + curr;
      });
    },
    generalScore() {
      return Math.ceil(this.calculatedGeneralScore / this.data.exam_question_groups.length);
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    calculateScore,
    close() {
      this.dialog = false;
      this.$modal?.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.my-results-modal {
  .assigned-on,
  .exam-name {
    font-weight: 400;
    font-size: 15px;
  }
  .exam-name {
    color: #2f80ed;
  }
  .time-spent {
    font-weight: 800;
    font-size: 12px;
    line-height: 24px;
  }
}
</style>
