<template>
  <div>
    <v-app-bar dense dark app color="primary" class="app-navigation-bar">
      <router-link to="/">
        <v-img src="@/assets/logo.svg" max-width="120" class="mr-5"></v-img>
      </router-link>
      <v-btn active-class="active-button" class="mr-2" v-if="isLoggedIn" text to="/jon-postings" disabled>Job Postings</v-btn>
      <v-btn active-class="active-button" v-if="isLoggedIn" text to="/xperexam/tests">XperExam</v-btn>
      <v-btn active-class="active-button" class="mr-2" v-if="isLoggedIn" text to="/jon-postings" disabled>Video Interview</v-btn>
      <v-spacer></v-spacer>
      <template v-if="user">
        <v-avatar size="36" color="grey">{{ avatarText }}</v-avatar>
        <div class="user-info">
          <div>
            <div class="full-name">{{ user.fullName }}</div>
            <div class="sub-text">recruiter</div>
          </div>
        </div>
        <v-icon class="mr-2" size="22">mdi-at</v-icon>
        <v-menu offset-y v-if="isLoggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="user-menu" v-bind="attrs" v-on="on">
              {{ user.email }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <div class="search-bar" v-if="!isPublic">
      <v-row align="center" justify="center">
        <v-col v-if="!hideSearch" md="3" offset-md="1">
          <v-text-field label="Search" class="search-input" outlined dense prepend-inner-icon="mdi-magnify" dark hide-details v-model="search"></v-text-field>
        </v-col>
        <v-col :md="hideSearch ? 7 : 6">
          <v-tabs dark background-color="transparent">
            <v-tab to="/xperexam/tests">My Tests</v-tab>
            <v-tab to="/xperexam/candidates">My Candidates</v-tab>
            <v-tab to="/xperexam/results">My Results</v-tab>
            <v-tab to="/xperexam/question-pool">Question Pool</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

const hideSearchRoutes = ['ResultDetail'];
export default {
  name: 'AppNavigationBar',
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
    };
  },
  methods: {
    ...mapMutations(['SET_SEARCH_TEXT']),
    logout() {
      this.$keycloak.logoutFn();
    },
  },
  computed: {
    ...mapState(['searchText']),
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', ['avatarText']),
    hideSearch() {
      return this.$route?.name && hideSearchRoutes.includes(this.$route?.name);
    },
    isLoggedIn() {
      return this.$keycloak.authenticated;
    },
  },
  watch: {
    search() {
      this.SET_SEARCH_TEXT(this.search);
    },
  },
};
</script>

<style lang="scss">
.app-navigation-bar {
  &.v-toolbar__content {
    border-bottom: 1px solid;
  }

  &.v-app-bar--fixed {
    box-shadow: none !important;
  }

  .active-button {
    background-color: white;
    color: var(--primary-color);
    &::before {
      opacity: 0;
    }
  }

  .v-btn {
    border-radius: 0;
    text-transform: none;
  }

  .user-info {
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .full-name {
      font-weight: bold;
      font-size: 14px;
    }

    .sub-text {
      color: #bbbbbb;
      font-size: 14px;
      margin-top: -5px;
    }
  }

  .user-menu {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 27px;
    background-color: transparent !important;
  }
}

.search-bar {
  background-color: var(--primary-color);
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  border-top: 1px solid silver;

  .search-input {
    border-radius: 30px;
    fieldset {
      border-color: white;
    }
  }
}
</style>
