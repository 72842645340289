<template>
  <AppScaffolding :user="user" :avatar-text="avatarText" :title="`All Tests (${this.totalCount})`">
    <template v-slot:left></template>
    <template v-slot:top>
      <div>
        <v-menu bottom left offset-y v-if="selectedExams.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="mr-2" v-bind="attrs" v-on="on">
              <span class="selected-item-count">{{ selectedExams.length }}</span>
              Selected
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="action-menu">
            <div class="action-menu-item" @click="assign(item)">Send to a Candidate</div>
            <v-divider></v-divider>
            <div class="action-menu-item">Attach to Job Posting</div>
            <v-divider></v-divider>
            <div class="action-menu-item">See All Results</div>
            <v-divider></v-divider>
            <div class="action-menu-item">Edit</div>
          </div>
        </v-menu>
        <v-btn outlined @click="addExamClicked()">Add New</v-btn>
      </div>
    </template>
    <div class="my-exam-list">
      <v-data-table
        :loading="loading"
        :items="exams"
        :headers="headers"
        hide-default-footer
        show-select
        v-model="selectedExams"
        dense
        :options.sync="options"
        :server-items-length="totalCount"
      >
        <template v-slot:[`item.date_created`]="{ item }">
          <span class="gray-column">
            {{ formatDate(item.date_created, 'DD MMM YYYY') }}
          </span>
        </template>
        <template v-slot:[`item.question_count`]="{ item }">
          <span class="gray-column">
            {{ item.question_count }}
          </span>
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <Tags :tags="item.exam_question_groups" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <div class="action-menu">
              <div class="action-menu-item" @click="assign(item)">Send to a Candidate</div>
              <v-divider></v-divider>
              <div class="action-menu-item">Attach to Job Posting</div>
              <v-divider></v-divider>
              <div class="action-menu-item">See All Results</div>
              <v-divider></v-divider>
              <div class="action-menu-item" @click="addExamClicked(item)">Edit</div>
            </div>
          </v-menu>
        </template>
      </v-data-table>
      <AppPagination :total="totalCount" :limit="this.limit" :page="page" @change="(v) => (page = v)" />
    </div>
  </AppScaffolding>
</template>

<script>
import debounce from 'lodash.debounce';
import Tags from '../Common/Tags.vue';
import AppPagination from '../Common/AppPagination';
import AppScaffolding from '../Common/AppScaffolding';
import CreateExamModal from '../Modals/CreateExamModal';
import AssignExamModal from '../Modals/AssignExamModal';
import api from '../../lib/services/api';
import { formatDate } from '../../lib/utils/date';
import { EXAM_LIST_HEADERS } from '../../lib/constants/headers';

export default {
  name: 'ExamList',
  props: {
    user: Object,
    avatarText: String,
    searchText: String,
  },
  components: {
    Tags,
    AppPagination,
    AppScaffolding,
  },
  data() {
    return {
      loading: false,
      headers: EXAM_LIST_HEADERS,
      selectedExams: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      exams: [],
      options: {},
    };
  },
  created() {
    this.fetchExams();
    if (this.$route.query.ref === 'questionGroup') {
      this.addExamClicked();
      this.$router.replace({ query: {} });
    }
  },
  computed: {
    examParams() {
      const { sortBy, sortDesc } = this.options;
      const q = {
        limit: this.limit,
        offset: this.page > 1 ? (this.page - 1) * this.limit : 0,
      };

      if (sortBy && sortBy[0]) {
        q.sort_by = sortDesc[0] ? `-${sortBy[0]}` : sortBy[0];
      }

      if (this.searchText) {
        q.exam_name = this.searchText;
      }

      return q;
    },
  },
  methods: {
    formatDate,
    addExamClicked(editModel) {
      this.$showModal(
        CreateExamModal,
        { editModel },
        {
          completed: this.fetchExams,
        },
      );
    },
    async fetchExams() {
      try {
        this.loading = true;
        const response = await api.fetchExams(this.examParams);
        this.exams = response.data?.exam_list || [];
        this.totalCount = response.data?.total_element || 0;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$emit('error', err);
      }
    },
    search: debounce(function () {
      this.fetchExams();
    }, 500),
    assign(item) {
      const exam = { id: item.id };
      this.$showModal(AssignExamModal, { exam });
    },
  },
  watch: {
    options: {
      deep: true,
      handler: 'fetchExams',
    },
    page: 'fetchExams',
    searchText: 'search',
  },
};
</script>

<style lang="scss" scoped>
.selected-item-count {
  color: var(--primary-color);
  margin-right: 10px;
  font-weight: bold;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}
</style>
