<template>
  <v-pagination class="app-pagination" total-visible="5" v-model="currentPage" @input="onPageChange" :length="totalPage"></v-pagination>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    total: {
      type: Number,
      default: 10,
    },
    limit: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: this.page || 1,
    };
  },
  methods: {
    onPageChange() {
      this.$emit('change', this.currentPage);
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.currentPage = this.page || 1;
      },
    },
  },
};
</script>

<style lang="scss">
.app-pagination {
  .v-pagination {
    width: auto;
  }

  .v-pagination__item {
    background-color: #eff2f7 !important;
  }

  .v-pagination__navigation {
    display: none;
  }
}
</style>
