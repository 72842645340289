<template>
  <v-app>
    <AppNavigationBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppNavigationBar from '@/components/Common/AppNavigationBar';

export default {
  name: 'Layout',
  components: {
    AppNavigationBar,
  },
};
</script>
