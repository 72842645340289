<template>
  <v-dialog v-model="dialog" max-width="300px" @click:outside="close">
    <v-card :loading="loading">
      <v-card-title>Add Rule</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field v-model="rule.min_value" min="0" label="Min" type="number" class="mb-2" dense outlined hide-details></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="rule.max_value" min="0" label="Max" type="number" dense outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-text-field v-model="rule.result" label="Result" dense outlined hide-details></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close">Cancel</v-btn>
        <v-btn color="primary" text @click="add" :loading="loading" :disabled="saveIsDisabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddRuleToGroupModal',
  props: {
    show: Boolean,
    editModel: Object,
  },
  data() {
    return {
      dialog: true,
      loading: false,
      rule: {
        id: null,
        min_value: 0,
        max_value: 0,
        result: null,
      },
    };
  },
  computed: {
    saveIsDisabled() {
      return !this.rule.result || (this.rule.min_value !== 0 && !this.rule.min_value) || (this.rule.max_value !== 0 && !this.rule.max_value);
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
      this.$modal.close();
    },
    setDefault() {
      this.rule = {
        min_value: 0,
        max_value: 0,
        result: null,
      };
    },
    async add() {
      const rule = {
        min_value: +this.rule.min_value,
        max_value: +this.rule.max_value,
        result: this.rule.result,
      };
      if (!rule.id) {
        rule.tempId = Math.round(Math.random() * 9999);
      }
      this.$emit('completed', rule);
    },
  },
  watch: {
    show() {
      this.dialog = this.show;
    },
    editModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.editModel) {
          this.rule = Object.assign({}, this.editModel);
        } else {
          this.setDefault();
        }
      },
      dialog() {
        if (!this.dialog) {
          this.$emit('close');
        }
      },
    },
  },
};
</script>
