<template>
  <v-dialog v-model="dialog" max-width="300px" @click:outside="close">
    <v-card :loading="loading">
      <v-card-title>Add Question</v-card-title>
      <v-card-text>
        <p>{{ question && question.question }}</p>
        <v-text-field v-model="score" label="Score" type="number" class="mb-2" dense outlined hide-details></v-text-field>
        <v-text-field v-model="order" label="Order" type="number" dense outlined hide-details></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="add" :loading="loading" :disabled="saveIsDisabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddQuestionToGroupModal',
  props: {
    question: Object,
  },
  data() {
    return {
      dialog: true,
      loading: false,
      score: null,
      order: null,
    };
  },
  computed: {
    saveIsDisabled() {
      return !this.score || !this.order;
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.dialog = false;
      this.$modal.close();
    },
    async add() {
      const payload = {
        ...this.question,
        score: +this.score,
        order: +this.order,
      };

      if (!payload.id) {
        payload.tempId = Math.round(Math.random() * 9999);
      }

      this.$emit('completed', payload);
      this.close();
    },
  },
};
</script>
