<template>
  <div class="my-exam-prestart">
    <v-skeleton-loader type="card" v-if="loading" min-height="500px"></v-skeleton-loader>
    <v-container v-else>
      <v-row class="mt-10">
        <v-col offset-md="1">
          <h3>Your Info</h3>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="1" md="6">
          <v-text-field v-model="username" label="Name Surname *" dense outlined hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="1" md="4">
          <v-text-field
            v-model="email"
            label="Email"
            dense
            outlined
            hint="*You will be contacted via e-mail written above."
            persistent-hint
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="1">
          <h3>Text Description</h3>
          <v-divider class="mb-5"></v-divider>
          <p>
            This test is sent you from
            <a href="https://www.bites.com.tr/" target="_blank">Bites Savunma Havacılık ve Uzay Teknolojileri A.Ş.</a>
          </p>
          <ul>
            <li>This test contains {{ questionSize }} questions. You have {{ examDuration }} minutes to complete it.</li>
            <li>If you leave the test page before completing, your test will be counted as uncompleted to recruiter.</li>
            <li>If you faced with any other issue kindly contact with the recrutier by clicking his/her name above.</li>
          </ul>
          <v-row align="center" class="mt-5">
            <v-checkbox v-model="sharingPolicy"></v-checkbox>
            <div>
              I agree information sharing
              <span class="link" @click="policyDialog = !policyDialog">policy and terms of use for XperHR</span>
            </div>
          </v-row>
          <v-row justify="center" class="mt-10 text-gray">
            <span>After clicking button below, you will be given 60 seconds to get ready.</span>
          </v-row>
          <v-row justify="center" class="mt-10">
            <v-btn color="primary" @click="startExam">Start to Test</v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="policyDialog" max-width="600px">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">Privacy Policy</v-card-title>
          <v-card-text class="mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                policyDialog = false;
                sharingPolicy = true;
              "
            >
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import api from '../lib/services/api';
import errorCode from '../lib/constants/errorCode';

export default {
  name: 'Prestart',
  data() {
    return {
      sharingPolicy: false,
      username: '',
      email: '',
      loading: true,
      questionSize: 0,
      examDuration: 0,
      policyDialog: false,
    };
  },
  created() {
    this.checkExam();
  },
  methods: {
    async checkExam() {
      try {
        const response = await api.fetchAssignedExamById(this.$route.params.examId);
        if (response.data.start_date) {
          this.$router.push(`/exam/${this.$route.params.examId}`);
          return;
        }
        if (response.data.finish_date) {
          this.$router.push(`/exam/${this.$route.params.examId}`);
          return;
        }
        this.questionSize = response.data.all_questions.length;
        this.examDuration = response.data.duration / 60;
        this.email = response.data.assigned_email;
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async startExam() {
      try {
        if (!this.username) {
          this.$snotify.error('Please fill out all required fields');
          return;
        }
        if (!this.sharingPolicy) {
          this.$snotify.error('Please agree sharing policy and terms of use for XperHR.');
          return;
        }
        await api.startExam(this.$route.params.examId, {
          email: this.email,
        });
        this.$router.push(`/exam/${this.$route.params.examId}`);
        this.loading = false;
      } catch (err) {
        this.$snotify.error(errorCode[err.response.data.error_code]);
        this.loading = false;
      }
    },
  },
};
// TODO: web in altına taşınacak.
</script>

<style scoped lang="scss">
.link {
  text-decoration: underline;
  color: rgb(43, 92, 226);
  cursor: pointer;
}
</style>
