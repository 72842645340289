<template>
  <div>
    <div class="editor-options">
      <v-select
        class="lang-select mr-3"
        hide-details
        :items="languages"
        label="Please select language"
        solo
        @input="setSelected"
        :value="selectedLang"
      ></v-select>
      <v-btn class="reset-btn" @click="resetCode">
        Reset
        <v-icon>mdi-replay</v-icon>
      </v-btn>
    </div>
    <editor class="mb-3" v-model="content" @init="editorInit" :lang="selectedLang" theme="twilight" width="50%" height="300"></editor>
    <v-btn class="mb-3" @click="runCode">
      Run
      <v-icon>mdi-play-circle</v-icon>
    </v-btn>
    <v-textarea
      class="terminal-output"
      v-model="codeOutput"
      solo
      name="input-7-4"
      label="Output"
      v-bind:disabled="true"
      background-color="grey lighten-2"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'CodeEditor',
  components: {
    editor: require('vue2-ace-editor'),
  },
  data() {
    return {
      languages: ['javascript', 'java', 'python'],
      selectedLang: 'javascript',
      content: '',
      codeOutput: '',
    };
  },
  methods: {
    editorInit: function () {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/javascript');
      require('brace/mode/java');
      require('brace/mode/python'); //language
      require('brace/theme/twilight');
      require('brace/snippets/javascript'); //snippet
    },
    setSelected(value) {
      this.selectedLang = value;
    },
    runCode() {
      try {
        this.codeOutput = eval(this.content);
      } catch (err) {
        this.codeOutput = 'There is an error: ' + err;
      }
    },
    resetCode() {
      this.content = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-select {
  max-width: 150px;
}
.terminal-output {
  margin-top: 10px;
  width: 50%;
}
.editor-options {
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
}
</style>
