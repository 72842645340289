<template>
  <v-dialog v-model="dialog" width="600px" @click:outside="close">
    <v-card class="assign-exam-modal">
      <v-card-title class="text-h5 grey lighten-2 mb-5">Assign Exam To User</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" label="Select Date" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" :min="minDate" :show-current="false" @input="dateMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="duration"
              type="number"
              label="Duration in Minutes"
              hide-details
              outlined
              dense
              @keypress="onDurationKeypress"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="time" label="Select Time" outlined dense readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-time-picker v-if="timeMenu" v-model="time" full-width @click:minute="$refs.menu.save(time)"></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div>
          <v-autocomplete
            :items="emails"
            v-model="emails"
            :search-input.sync="emailSearchValue"
            outlined
            dense
            deletable-chips
            multiple
            append-icon
            small-chips
            hide-selected
            hide-no-data
            persistent-hint
            label="Emails"
            @change="changeMails"
            @keydown.enter.stop="onEnterEmail"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip close small @click:close="unselectEmail(index)" class="my-1">
                <div class="chip-content">{{ item }}</div>
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
        <v-alert v-if="emailError" dense outlined type="error">Emails field is required</v-alert>
        <v-alert v-if="isSmallerTwoDays" dense outlined type="error">Deadline must be at least 2 days after today</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" :loading="loading" @click="assign">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '../../lib/services/api';
import { validateEmail } from '../../lib/utils/validation';

export default {
  name: 'AssignExamModal',
  props: {
    exam: Object,
    fetchAfterCreate: Boolean,
  },
  data() {
    return {
      date: null,
      minDate: null,
      dialog: true,
      loading: false,
      dateMenu: false,
      emailError: false,
      emailSearchValue: '',
      time: null,
      timeMenu: false,
      duration: null,
      emails: [],
    };
  },
  computed: {
    datePayload() {
      const [hour, minutes] = this.time.split(':');
      let date = new Date(this.date);
      date.setHours(hour, minutes);
      return date;
    },
    isSmallerTwoDays() {
      const now = new Date();
      now.setDate(now.getDate() + 2);
      return now.getTime() >= this.datePayload.getTime();
    },
  },
  created() {
    let now = new Date();
    if (this.exam?.deadline_date) {
      now = new Date(this.exam?.deadline_date);
    }
    let minutes = now.getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    const hour = now.getHours();
    this.time = `${hour}:${minutes}`;
    if (!this.exam?.deadline_date) {
      now.setDate(now.getDate() + 2);
    }
    this.date = now.toISOString().substr(0, 10);
    this.minDate = now.toISOString().substr(0, 10);
    this.duration = this.exam?.duration;
    if (this.exam?.assigned_email) {
      this.emails = [this.exam.assigned_email];
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.$modal?.close();
    },
    onDurationKeypress(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onEnterEmail() {
      if (!validateEmail(this.emailSearchValue.trim())) {
        return;
      }

      if (!this.emails.includes(this.emailSearchValue.trim())) {
        this.emails.push(this.emailSearchValue.trim());
        this.emailSearchValue = '';
      }
    },
    unselectEmail(index) {
      this.emails.splice(index, 1);
    },
    changeMails(val) {
      this.emails = [...val];
    },

    async assign() {
      this.emailError = false;
      if (!this.emails.length) {
        this.emailError = true;
        return;
      }
      if (this.isSmallerTwoDays) {
        return;
      }
      this.loading = true;
      try {
        const payload = {
          exam_id: this.exam.id,
          deadline_date: this.datePayload.toISOString(),
          assignee_list: this.emails.map((email) => {
            return {
              assigned_email: email,
            };
          }),
        };
        if (this.duration) {
          payload.duration = Number(this.duration);
        }
        await api.assignExamToUser(payload);
        if (this.fetchAfterCreate) {
          this.$emit('fetchExams');
        }
        this.loading = false;
        this.$snotify.success('Exam was successfully assigned');
        this.close();
      } catch (error) {
        this.loading = false;
        this.$snotify.error("Couldn't assigned exam");
      }
    },
  },
};
</script>
